(function() {
    'use strict';


    // constantes
    let URL_FUNIL;
    let URL_API;
    let URL_SOCKET;
    let URL_HTML5;
    let URL_API_FUNIL;
    let URL_NEGOCIACAO;
    let URL_DASHBOARD;

    if ( window.location.host == 'homolog.funil.pro' ) { 
        URL_FUNIL = 'https://homolog.funil.pro/';
        URL_API = 'https://homolog-api.funil.pro/api/v1/';
        URL_SOCKET = 'https://homolog-api.funil.pro/';
        URL_HTML5 = 'homolog.funil.pro/notificacao';
        URL_API_FUNIL = 'homolog.funil.pro/';
        URL_NEGOCIACAO = 'https://negociacao.homolog.funil.pro';
        URL_DASHBOARD = 'https://dashboards.homolog.funilpromkt.com.br';
    } else if ( window.location.host == 'funil.pro' ) { 
        URL_FUNIL = 'https://funil.pro/';
        URL_API = 'https://api.funil.pro/api/v1/';
        URL_SOCKET = 'https://api.funil.pro/';
        URL_HTML5 = 'https://funil.pro/notificacao';
        URL_API_FUNIL = 'https://funil.pro/';
        URL_NEGOCIACAO = 'https://negociacao.funil.pro';
        URL_DASHBOARD = 'https://dashboards.funilpromkt.com.br';
    } else {
        URL_FUNIL = window.location.origin + '/';
        URL_API = window.location.hostname == 'localhost' ? 'http://localhost:4000/api/v1/' : 'https://api.funil.pro/api/v1/';
        URL_SOCKET = window.location.hostname == 'localhost' ? 'http://localhost:4000/' : 'https://api.funil.pro/';
        URL_HTML5 = window.location.origin + '/notificacao';
        URL_API_FUNIL = window.location.origin + '/';
        URL_NEGOCIACAO = 'http://localhost:4200';
        URL_DASHBOARD = 'http://localhost:3000'
    }

    angular
        .module('app')
        .constant('CONST_FRONT', {
            negociacao: URL_NEGOCIACAO,
            dashboard: URL_DASHBOARD
        })
        .constant('API', {
            url: URL_FUNIL,
            funilRC : new RC4('funil') // cria objeto encry/decry
        })
        .constant('RC', {
            funilRC : new RC4('funil')
        })
        .constant('APINode', {
            url : URL_API,
            socket : URL_SOCKET,
            notLinkHTML5: URL_HTML5,
            funil: URL_API_FUNIL
        })
        .constant('_under',
            window._
        );
})();