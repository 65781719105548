/*
 * @Author: Glauber Funez
 * @Date:   2018-03-19 11:32:59
 * @Last Modified by:   Glauber Funez
 * @Last Modified time: 2018-08-21 09:39:52
 */

(function() {
    'use strict';

    angular
        .module('pessoas')
        .controller('infoNegociosController', infoNegociosController);

    infoNegociosController.$inject = ['$routeParams', '$scope', 'mensagem', '$location', 'negociosService', 'PessoasService', '$http', 'API', '$filter', '$timeout', '$window', '$document', '$q', 'loadSaas', 'validarSaas', 'verificaLeads', 'produtosService', 'FactoryRoles', '$log', '$rootScope', 'compromissosService', 'APINode', 'RC', 'FactoryWebhook', 'FactoryNotificacao', 'origemContatoService', 'FactoryLogEmail', 'tokenNodejs', 'FactoryModalMotivo', 'loadFunisVenda', 'turmasVCService'];

    function infoNegociosController($routeParams, $scope, mensagem, $location, negociosService, PessoasService, $http, API, $filter, $timeout, $window, $document, $q, loadSaas, validarSaas, verificaLeads, produtosService, FactoryRoles, $log, $rootScope, compromissosService, APINode, RC, FactoryWebhook, FactoryNotificacao, origemContatoService, FactoryLogEmail, tokenNodejs, FactoryModalMotivo, loadFunisVenda, turmasVCService) {
        var vm = this;

        vm.resultAlteraRole = FactoryRoles.checkRolesAltera('isModNegociacao');
        vm.resultExcluiRole = FactoryRoles.checkRolesExclui('isModNegociacao');
        vm.resultVisuFunil = FactoryRoles.checkRolesVisuFUNIL('isModFunil');
        vm.descontoNegociacao = [];
        vm.descontoNegociacaoUpdateBanco = [];
        vm.descontoNegociacaoIndex = [];
        angular.element('#msgDescMaiorTotal').hide();
        vm.descontNeg = {
            "porc": false,
            "valor": "",
            "porcentagem": ""
        }


        vm.carregarTitulo = function() {
            vm.slugClienteN = $routeParams.slugCliente;
            PessoasService.findUnique($routeParams.slugCliente).then(function(response) {
                vm.titulo_pagina = response.data[0].cli_nome;
                vm.ativo = response.data[0].cli_ativo;

                console.log("findUnique");
                console.log(response.data);
                vm.cliente = {
                    'nome': response.data[0].cli_nome,
                    'slug': response.data[0].cli_slug,
                    'email': response.data[0].cli_email,
                    'telefone': response.data[0].cli_telefone,
                    'telefone2': response.data[0].cli_telefone2,
                    'tipo': 'p'
                }
            }, function(error) {
                $log.error('Erro: ', error);
            });
        };


        function freezGF(arr) {
            var returnDados = [];
            for (var i = 0; i < arr.length; i++) {

                // Recuperar os nomes de propriedade definidos em obj
                var propNames = Object.getOwnPropertyNames(arr[i]);

                // Congelar as propriedades antes de congelar-se
                propNames.forEach(function(name) {
                    var prop = arr[i][name];

                    // Congele prop se for um objeto
                    if (typeof prop == 'object' && prop !== null)
                        freezGF(prop);
                });

                // Congele-se (não faz nada se já estiver congelado)
                // return Object.freeze(arr[i]);
                returnDados.push(Object.freeze(arr[i]))
            }
            return returnDados;
        }

        var truVisuGroupCargoSelec = FactoryRoles.visuGroupCargoSelec('isModConfig');
        vm.veriVisuSelectCargCSS = function(dados) {
            if (dados == false) {
                var desabilitarSelect = 'disable_a2';
                return desabilitarSelect;
            }
        };
        vm.desabilitarSelect = vm.veriVisuSelectCargCSS(truVisuGroupCargoSelec);
        // verificar se for falso, retornar um true para colocar na class


        if ($routeParams.slugCliente) { // carregar nome do cliente e slug para os links
            vm.carregarTitulo();
        }

        vm.verificarAdm = function() {
            PessoasService.verificarAdm(Cookies.get('crm_funil_hash')).then(function(response) {
                if (response.data == 'Administrador') {
                    vm.adm = true;
                } else {
                    vm.adm = false;
                }
            }, function(error) {
                $log.error('Erro: ', error);
            });
        };
        vm.verificarAdm();

        vm.negocios = {
            'slug': '',
            'nome': '',
            'cliente_empresa': '',
            'qual_selecionado': '',
            'valor': '',
            'prazo': '',
            'etapa': '1',
            'ganhou': '0',
            'perdeu': '0',
            'observacao': '',
            'responsavel': '',
            'data_ganho_perda': ''
        };

        vm.validar = {
            'nome': false,
            'cliente_empresa': false,
            'valor': false,
            'prazo': false,
            'responsavel': false,
        };

        vm.limpar = function() {
            vm.negocios = {
                'nome': '',
                'cliente_empresa': '',
                'qual_selecionado': '',
                'valor': '',
                'prazo': '',
                'etapa': '1',
                'ganhou': '0',
                'perdeu': '0',
                'observacao': '',
                'responsavel': '',
                'data_ganho_perda': ''
            };
        };

        vm.reativarCliente = function() {
            swal({
                    title: 'Deseja realmente reativar esta pessoa?',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#DD6B55',
                    confirmButtonText: 'Sim',
                    cancelButtonText: 'Não',
                    closeOnConfirm: false
                },
                function() {
                    $window.onkeydown = null;
                    $window.onfocus = null;

                    PessoasService.findUnique($routeParams.slugCliente).then(function(response) {
                        if (response.data[0].cli_email != '') { // E-MAIL ESTÁ PREENCHIDO
                            var dados = {
                                'slug': $routeParams.slugCliente,
                                'saasid': vm.saasid,
                                'email': response.data[0].cli_email
                            };
                            PessoasService.validaExisteEmailEdit(dados).then(function(response) {
                                if (response.data != '') {
                                    if (response.data[0].tipo == 'p') {
                                        swal({
                                            title: 'Operação não realizada!',
                                            text: 'Já existe uma pessoa ativa com o e-mail informado.<br><span style=\'font-size:12px;font-weight: 300;\'>Clique no link abaixo para acessá-la.</span><br><br><a href=\'/pessoas/info/' + response.data[0].slug + '\' target=\'_blank\' class=\'nome_cliente_existe_email\'>' +
                                                response.data[0].nome + '</a>',
                                            type: 'error',
                                            showConfirmButton: true,
                                            html: true
                                        }, function() {
                                            $timeout(function() {
                                                $('#email').focus();
                                            });
                                        });
                                    } else if (response.data[0].tipo == 'e') {
                                        swal({
                                            title: 'Operação não realizada!',
                                            text: 'Já existe uma empresa ativa com o e-mail informado.<br><span style=\'font-size:12px;font-weight: 300;\'>Clique no link abaixo para acessá-la.</span><br><br><a href=\'/empresas/info/' + response.data[0].slug + '\' target=\'_blank\' class=\'nome_cliente_existe_email\'>' +
                                                response.data[0].nome + '</a>',
                                            type: 'error',
                                            showConfirmButton: true,
                                            html: true
                                        }, function() {
                                            $timeout(function() {
                                                $('#email').focus();
                                            });
                                        });
                                    }
                                } else {
                                    vm.reativar();
                                }
                            }, function(error) {
                                $log.error(error);
                            });
                        } else { // NÃO TEM E-MAIL PREENCHIDO
                            vm.reativar();
                        }
                    }, function(error) {
                        $log.error(error);
                    });
                });
        };

        vm.reativar = function() {
            // LIMITAÇÃO DE LEADS --- INÍCIO
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) {
                vm.saasid = items;
                vm.leads = verificaLeads(vm.saasid); // carregar saas id
                vm.leads.then(function(info) {
                    if (info.limite <= info.quantia) {
                        swal.close();
                        $('#modalAlertaLeads').modal({
                            backdrop: 'static',
                            keyboard: false
                        });
                        vm.salvando = false;
                    } else {
                        var dados = {
                            'slug': $routeParams.slugCliente,
                        };
                        PessoasService.reativaCliente(dados).then(function(response) {
                            swal({
                                title: 'Reativado!',
                                text: 'Pessoa reativada com sucesso!',
                                type: 'success',
                                showConfirmButton: false,
                                timer: 2000
                            });
                            $timeout(function() {
                                vm.carregarTitulo();
                            }, 2000);
                        }, function(error) {
                            $log.error(error);
                        });
                    }
                });
            });
            // LIMITAÇÃO DE LEADS --- FIM
        };

        String.prototype.replaceAll = function(de, para) {
            var str = this;
            var pos = str.indexOf(de);
            while (pos > -1) {
                str = str.replace(de, para);
                pos = str.indexOf(de);
            }
            return (str);
        };


        vm.tooltipsterNegDESC = function() {
            $timeout(function() {
                if (vm.mobile) {
                    var acao = 'click';
                    var posicao = 'left';
                } else {
                    var acao = 'hover';
                    var posicao = 'left';
                }
                $('.tooltipster_neg_desc').tooltipster({
                    interactive: true,
                    // trigger: acao,
                    maxWidth: 100,
                    minWidth: 100,
                    position: posicao,
                    theme: 'tooltipster-light',
                    delay: 100,
                    contentCloning: true
                });
            }, 10);
        };

        vm.tooltipsterMotivoPerda = function() {
            $timeout(function() {
                if (vm.mobile) {
                    var acao = 'click';
                    var posicao = 'left';
                } else {
                    var acao = 'hover';
                    var posicao = 'right';
                }
                $('.tooltipster_motivo_perda').tooltipster({
                    interactive: true,
                    // trigger: acao,
                    maxWidth: 250,
                    minWidth: 100,
                    position: posicao,
                    theme: 'tooltipster-light',
                    delay: 100,
                });
            });
        };


        vm.buscarHistoriDescNeg = function(id, index) {

            /** Busca descontos para serem mostrados na tela de detalhe*/
            if (index) {
                vm.descontoNegociacaoIndex = [];
                var idList = $routeParams.slugNegocio.split('-');

                negociosService.buscarHistoriDescNeg(idList["0"]).then(function(response) {

                    var histDescAtivoIndex = response.data.filter(function(elem) {
                        return elem.propdesc_ativo == 1
                    })

                    var histDescAtivoIndexTemp = [];
                    for (var i = 0; i < histDescAtivoIndex.length; i++) {

                        histDescAtivoIndexTemp.push({
                            "propdesc_id": histDescAtivoIndex[i].propdesc_id,
                            "fk_prop_id": histDescAtivoIndex[i].fk_prop_id,
                            "propdesc_desconto": histDescAtivoIndex[i].propdesc_desconto,
                            "propdesc_valor": histDescAtivoIndex[i].propdesc_valor,
                            "propdesc_is_porc": histDescAtivoIndex[i].propdesc_is_porc,
                            "sinal": histDescAtivoIndex[i].propdesc_is_porc == 1 ? '%' : '$'
                        })

                    }

                    $timeout(function() {
                        vm.descontoNegociacaoIndex = histDescAtivoIndexTemp;
                    });

                }, function(error) {
                    $log.error('Erro: ', error);
                })

            } else { /** Busca descontos para serem mostrados na edição da negociação */
                negociosService.buscarHistoriDescNeg(id).then(function(response) {

                    var dadosBeforeDesc = response.data;
                    // vm.historBeforeDesc = freezGF(dadosBeforeDesc);
                    vm.historBeforeDesc = dadosBeforeDesc;

                    var histDescAtivo = response.data.filter(function(elem) {
                        return elem.propdesc_ativo == 1
                    })

                    var histDescInativo = response.data.filter(function(elem) {
                        return elem.propdesc_ativo == 0
                    })

                    var count = 0;

                    // if (count < histDescAtivo.length -1) {
                    for (var i = 0; i < histDescAtivo.length; i++) {
                        count = count + 1;


                        vm.descontoNegociacao.push({
                            "propdesc_id": histDescAtivo[i].propdesc_id,
                            "fk_prop_id": histDescAtivo[i].fk_prop_id,
                            "propdesc_desconto": histDescAtivo[i].propdesc_desconto,
                            "propdesc_valor": histDescAtivo[i].propdesc_valor,
                            "propdesc_is_porc": histDescAtivo[i].propdesc_is_porc,
                            "sinal": histDescAtivo[i].propdesc_is_porc == 1 ? '%' : '$'
                        })

                        // vm.descontoNegociacao = Array.from(new Set(vm.descontoNegociacao))
                    }
                    // }

                }, function(error) {
                    $log.error('Erro: ', error);
                });
            }
        }


        vm.buscarProdutosProposta = function(slug_proposta) {
            var dados = {
                'slug_proposta': slug_proposta
            };
            produtosService.buscarProdutosProposta(dados).then(function(response) {
                console.log('buscarProdutosProposta');
                console.log(response.data);
                if (response.data != '') {
                    vm.produtosAdd = response.data;
                    console.log(response.data)
                    for (var i = 0; i < vm.produtosAdd.length; i++) {
                        vm.produtosAdd[i].prod_qtd = parseInt(vm.produtosAdd[i].prod_qtd)
                    }
                    // vm.verificarValores();
                } else {
                    vm.produtosAdd = [];
                    if (vm.negocios.valor != 0 && vm.negocios.valor) {
                        vm.valorPadraoAlterado = true;
                    }
                }
            }, function(error) {
                $log.error('Erro: ', error);
            });
        };

        vm.carregardadosNeg = function(editando) {
            var dados = {
                'slug': $routeParams.slugNegocio
            };
            vm.buscarProdutosProposta($routeParams.slugNegocio);
            negociosService.carregarNegocio(dados).then(function(response) {
                // console.log("carregarNegocio");
                // console.log(response.data);
                var TempTotalLiquidBefore = response.data["0"].prop_valor_total

                vm.getTotalLiquidNegTelaList = response.data["0"].prop_valor_total;
                // vm.TotalLiquidBefore = freezGF(TempTotalLiquidBefore);
                vm.TotalLiquidBefore = TempTotalLiquidBefore;

                $timeout(function() {
                    if (response.data[0].fk_cli_id) {
                        $('#pessoa_empresa_selecionado').attr('value', 'p');
                        var cliente_empresa = {
                            'id': response.data[0].fk_cli_id,
                            'nome': response.data[0].cli_nome,
                            'tipo': 'Pessoas',
                        };
                    } else {
                        $('#pessoa_empresa_selecionado').attr('value', 'e');
                        var cliente_empresa = {
                            'id': response.data[0].fk_emp_id,
                            'nome': response.data[0].emp_nome,
                            'slug': response.data[0].emp_slug,
                            'tipo': 'Empresas',
                        };
                    }
                    if (editando == '0') { // SÓ EXECUTA QUANDO CARREGA A TELA
                        vm.buscaUsuarios(response.data[0].user_hash);
                    }
                    vm.negocios.slug = $routeParams.slugNegocio;
                    vm.negocios.nome = response.data[0].prop_nome;
                    vm.negocios.id = response.data[0].prop_id;
                    vm.negocios.cliente_empresa = cliente_empresa;
                    vm.negocios.valor = response.data[0].prop_valor;
                    vm.negocios.prazo = response.data[0].prop_prazo;
                    vm.negocios.funv_id = response.data[0].fk_funv_id;
                    vm.negocios.fk_tvc_id = response.data[0].fk_tvc_id;
                    
                    vm.valorProposta = response.data[0].prop_valor;
                    vm.contrato = response.data[0].prop_contrato == '1' ? true : false;
                    vm.pagOnline = response.data[0].prop_pagamento_online == '1' ? true : false;

                    vm.buscaTurmasVC();
                    vm.buscaEtapas();
                    if (response.data[0].prop_nao_automacao == '1') {
                        vm.negocios.naoEnviarAutomacao = true;
                    } else {
                        vm.negocios.naoEnviarAutomacao = false;
                    }
                    // aqui ja retorna 4
                    vm.SetTotalLiquidoNeg(vm.negocios.valor, 'get')

                    if (editando == '1') {
                        $('#campo_prazo').daterangepicker({
                            singleDatePicker: true,
                            showDropdowns: true,
                            locale: {
                                format: 'DD/MM/YYYY'
                            }
                        });
                        $('#campo_prazo').data('daterangepicker').setStartDate(response.data[0].prop_prazo);
                        $('.grafico_interna_cadastro').attr('value', response.data[0].etapa_posicao);
                    }
                    vm.negocios.etapa = response.data[0].etapa_posicao;
                    $('.grafico_interna').attr('value', response.data[0].etapa_posicao);
                    vm.etapaEscolhida = response.data[0].etapa_nome;
                    vm.negocios.ganhou = response.data[0].prop_ganho;
                    vm.negocios.perdeu = response.data[0].prop_perdido;
                    vm.negocios.data_ganho_perda = response.data[0].prop_data_ganho_perda;
                    vm.negocios.observacao = response.data[0].prop_observacao;
                    var obs = response.data[0].prop_observacao;
                    $('.observacao').html(obs.replaceAll('\n', '<br>'));
                    vm.negocios.responsavel_nome = response.data[0].user_nome;
                    vm.negocios.responsavel_ativo = response.data[0].user_ativo;
                    if (response.data[0].fk_user_id != 0) {
                        vm.negocios.responsavel = response.data[0].fk_user_id;
                    } else {
                        vm.negocios.responsavel = '';
                    }

                    vm.negocios.mot_descricao = response.data[0].mot_descricao;
                    vm.tooltipsterMotivoPerda();


                    // vm.buscarHistoriDescNeg()
                    vm.carregarHistorico(response.data[0].prop_id);
                    vm.listarInteracoes(response.data[0].prop_id);
                    vm.buscarCompromissosNeg();

                    vm.negocios.fk_gru_id = response.data[0].fk_gru_id;
                    vm.verifMostrarBtnEditar();
                    vm.valorPadraoAlterado = false;
                    // vm.verificarCookieOrcCompufacil();


                    // console.log(vm.negocios)


                });
            }, function(error) {
                console.log(error);
            });
        };

        vm.buscaDesIndex = function() {
            vm.buscarHistoriDescNeg(vm.negocios.id, 'index')
        }

        if ($routeParams.slugNegocio) {
            vm.carregardadosNeg(0);
            listLink();
        }

        vm.buscaEtapas = function(funil) {
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) { // ler array de retorno
                vm.saasid = items;
                var dados = {
                    'saasid': vm.saasid,
                    'funil_venda': vm.negocios.funv_id
                };
                console.log(dados);
                negociosService.buscaEtapas(dados).then(function(response) {
                    if (response.data != 0) {
                        vm.etapasLista = response.data;
                        if (funil){
                            $timeout(function() {
                                vm.marcarEtapaCadastro(1, vm.etapasLista[0].etapa_id);
                                vm.etapaEscolhida = vm.etapasLista[0].etapa_nome;
                            });
                        }
                    } else {
                        vm.etapasLista = '';
                    }
                }, function(error) {
                    $log.error(error);
                });
            });
        };
        //vm.buscaEtapas();

        vm.acabouRepeat = function() {
            $timeout(function() {
                var qtd_etapas = vm.etapasLista.length;
                var largura_barra_etapas = $('.grafico_interna_exibir').width();
                var tamanho_etapas = largura_barra_etapas / qtd_etapas;
                $('.etapas_exibir').css('width', tamanho_etapas + 'px');
                $('.etapas_exibir').fadeIn('slow');
            }, 200);
        };

        vm.acabouRepeatDesc = function() {
            $timeout(function() {
                vm.tooltipsterNegDESC()
            }, 200);
        };

        vm.acabouRepeatCadastro = function() {
            $timeout(function() {
                var qtd_etapas = vm.etapasLista.length;
                var largura_barra_etapas = $('.grafico_interna_cadastro').width();
                var tamanho_etapas = largura_barra_etapas / qtd_etapas;
                $('.etapas_cadastro').css('width', tamanho_etapas - 0.2 + 'px');
                $('.etapas_cadastro').fadeIn('slow');
            }, 200);
        };

        vm.destacarEtapasOver = function(posicao, prop_id) {
            var posicao_atual = $('.grafico_interna_exibir').attr('value');
            var destacar = posicao - posicao_atual;
            for (var i = 0; i < destacar; i++) {
                $('.etapa_' + posicao).addClass('etapa_marcada_over');
                posicao--;
            }
        };

        vm.destacarEtapasOverCadastro = function(posicao, prop_id) {
            var posicao_atual = $('.grafico_interna_cadastro').attr('value');

            var destacar = posicao - posicao_atual;
            for (var i = 0; i < destacar; i++) {
                $('.etapa_cad_' + posicao).addClass('etapa_marcada_over');
                posicao--;
            }
        };

        vm.removerDestaqueEtapas = function() {
            $('.etapas').removeClass('etapa_marcada_over');
        };

        vm.removerDestaqueEtapasCadastro = function() {
            $('.etapas').removeClass('etapa_marcada_over');
        };

        vm.destacarEdit = function(x, cl) {
            if (x == 0) {
                $('.' + cl).addClass('destacar_shadow');
            } else {
                $('.' + cl).removeClass('destacar_shadow');
            }
        };

        vm.marcarEtapa = function(prop_id, etapa_marcada, etapa_id) {
            FactoryWebhook.triggerUpdate(prop_id);

            var dados = {
                'prop_id': prop_id,
                'etapa_id': etapa_id,
                'user_hash': Cookies.get('crm_funil_hash')
            };
            $('.check_etapa_' + prop_id).hide(); // remove o check
            $('.spinner_etapa_' + prop_id).toggleClass('hide'); // aparecer loading
            negociosService.marcarEtapa(dados).then(function(response) {
                $('.grafico').removeClass('etapa_marcada');
                var marcada = etapa_marcada;
                for (var i = 0; i <= etapa_marcada; i++) {
                    $('.etapa_' + marcada).addClass('etapa_marcada');
                    marcada--;
                }
                $('.grafico_interna').attr('value', etapa_marcada);
                $('.spinner_etapa_' + prop_id).toggleClass('hide'); // remover loading
                $('.check_etapa_' + prop_id).fadeIn('slow'); // mostra check sucesso
                vm.carregarHistorico(prop_id);
                $timeout(function() {
                    $('.check_etapa_' + prop_id).fadeOut('slow'); // após 2 segundos remove o check
                }, 2000);
            }, function(error) {
                $log.error(error);
            });
        };

        vm.marcarEtapaCadastro = function(etapa_marcada, etapa_id) {
            $('.grafico_cadastro').removeClass('etapa_marcada');
            var marcada = etapa_marcada;
            for (var i = 0; i <= etapa_marcada; i++) {
                $('.etapa_cad_' + marcada).addClass('etapa_marcada');
                marcada--;
            }
            $('.grafico_interna_cadastro').attr('value', etapa_marcada);
            vm.negocios.etapa = etapa_marcada;
        };

        vm.carregarHistorico = function(prop_id, idEmail) {
            var dados = {
                'prop_id': prop_id
            };

            dados.idEmail = idEmail ? idEmail : null;

            negociosService.carregaHistorico(dados).then(function(response) {
                if (response.data != 0 || response.data != '') {
                    vm.listaHistorico = response.data;
                    vm.verificarNegExactSales(vm.listaHistorico);
                } else {
                    vm.listaHistorico = '';
                }
            }, function(error) {
                $log.error(error);
            });
        };

        /*INTEGRAÇÃO EXACT SALES --- INICIO*/

        vm.verificarNegExactSales = function(historico) {
            // VERIFICAR PELO HISTÓRICO SE ESTA NEGOCIAÇÃO FOI CRIADA PELA INTEGRAÇÃO COM O EXACT SALES
            angular.forEach(historico, function(value, key) {
                if (value.campo == 'exact_sales_spotter_1') {
                    vm.negociacaoSpotter = true;
                    vm.buscarEmpresaVinculada($routeParams.slugCliente);
                }
            })
        }

        vm.buscarEmpresaVinculada = function(slug) {
            var dados = {
                'slug': slug
            };
            PessoasService.listarEmpresaCliente(dados).then(function(response) {
                vm.listaPessoasEmpresas = [];
                if (response.data != 0 || response.data != '') {
                    angular.forEach(response.data, function(value, key) {
                        vm.listaPessoasEmpresas.push({
                            'id': value.id,
                            'nome': value.nome,
                            'slug': value.slug,
                            'tipo': value.tipo
                        });
                    });
                } else {
                    vm.growableOptions.emptyListText = 'Nenhuma empresa vinculada à pessoa';
                    angular.element(".spinner_pessoa_empresa").hide();
                }
                angular.element(".custom-select-action > button").addClass('disabled');
                angular.element(".custom-select-action > button").hide();
                // ALTERAR MENSAGEM QUANDO NÃO ENCONTRA NADA NA BUSCA
                angular.element(".box_PE .word-break").html('Negociação integrada ao Spotter. <br> Não é permitido alterar os dados para registros que não são vinculados à pessoa.');
            }, function(error) {
                $log.error(error);
            });
        };

        /*INTEGRAÇÃO EXACT SALES --- FIM*/

        vm.integrarCompufacil = function(id) {
            var dados = {
                'saasid': vm.saasid,
                'id_prop': id,
                'user_hash': Cookies.get('crm_funil_hash')
            }
            negociosService.integrarCompufacil(dados).then(function(response) {
                $timeout(function() {
                    vm.carregarHistorico(id);
                });
            }, function(error) {
                $log.error(error);
            });
        }

        vm.situacaoCompufacil = function(id) {
            var dados = {
                'saasid': vm.saasid,
                'id_prop': id,
                'user_hash': Cookies.get('crm_funil_hash')
            }
            negociosService.situacaoCompufacil(dados).then(function(response) {
                // console.log("**** situacaoCompufacil ****");
                // console.log(response.data);
                $timeout(function() {
                    vm.carregarHistorico(id);
                });
            }, function(error) {
                $log.error(error);
            });
        }

        vm.descartarLeadExactSales = function(id) {
            var dados = {
                'saasid': vm.saasid,
                'id_prop': id
            }
            negociosService.descartarLeadExactSales(dados).then(function(response) {
                // console.log("descartarLeadExactSales");
                // console.log(response.data);
                $timeout(function() {
                    vm.carregarHistorico(id);
                });
            }, function(error) {
                $log.error(error);
            });
        }

        vm.venderLeadExactSales = function(id) {
            var dados = {
                'saasid': vm.saasid,
                'id_prop': id
            }
            negociosService.venderLeadExactSales(dados).then(function(response) {
                // console.log("venderLeadExactSales");
                // console.log(response.data);
                $timeout(function() {
                    vm.carregarHistorico(id);
                });
            }, function(error) {
                $log.error(error);
            });
        }

        vm.marcar_situacao = function(qual, id, negocio, ev) {
            if ((!($('.perdeu_' + id).hasClass('marcado_perdeu')) && !($('.perdeu_' + id).hasClass('botao_perder_marcado'))) && qual == 2) { // CLICOU EM PERDEU E A NEGOCIAÇÃO NÃO ESTAVA PERDIDA
                $('.spinner_' + id).toggleClass('hide'); // aparecer loading
                $('.ganhou_' + id).toggleClass('hide'); // ocultar icone ganhou
                $('.perdeu_' + id).toggleClass('hide'); // ocultar icone perdeu
                vm.removerTooltipAcao(id);

                vm.modalMotivoNeg(negocio, ev);
            } else { // CLICOU EM GANHAR OU REABRIR NEGOCIAÇÃO
                if (qual == 1) { // CLICOU NO BOTÃO GANHOU
                    console.log('vm.cliente')
                    console.log(vm.cliente)
                    var nomeSobrenome = /\b[A-Za-zÀ-ú][A-Za-zÀ-ú]+,?\s[A-Za-zÀ-ú][A-Za-zÀ-ú]{2,19}\b/gi;
                    var email = /\S+@\S+\.\S+/;
                    var nomeValido = nomeSobrenome.test(vm.cliente.nome);
                    var emailValido = email.test(vm.cliente.email);
                    // console.log('nome válido')
                    // console.log(nomeSobrenome.test(vm.cliente.nome))
                    // console.log('email válido')
                    // console.log(emailValido.test(vm.cliente.email))
                    if(!nomeValido || !emailValido){
                        var texto = 'O cadastro do cliente deve possuir:<br/>';
                        if (!nomeValido) texto = texto + "Nome completo<br/>"
                        if (!emailValido) texto = texto + "E-mail Principal válido"
                        swal({
                            title: "Atenção!",
                            text: texto,
                            type: "warning",
                            html: true,
                            showConfirmButton: true
                        });
                        return false;
                    }
                    if (vm.negocios.ganhou != 1) { // NÃO ESTÁ MARCADO COMO 'GANHO', 'GANHAR' NEGOCIAÇÃO
                        swal({
                            title: 'Deseja realmente ganhar esta negociação?',
                            text:
                                '<div class=\'demais_info\'>'+
                                '<div class=\'subtitulo text-center mb-2\'>Os seguintes passos serão executados ao confirmar esta ação:</div>'+
                                '<ol>' +
                                '<li>A negociação será enviada aos quadros do Monday</li>'+
                                '<li>A negociação será enviada ao CMS para geração de cobrança</li>'+
                                '</ol>' +
                                '</div>'
                            ,
                            type: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: '#5cb85c',
                            confirmButtonText: 'Sim',
                            cancelButtonText: 'Não',
                            closeOnConfirm: false,
                            html: true,
                            customClass: 'box_dialog_enviar_todos'
                        },
                        function() {
                            $window.onkeydown = null;
                            $window.onfocus = null;
                            swal.close();

                            FactoryWebhook.triggerSit(id);
                            var dados = {
                                'qual': qual,
                                'id': id,
                                'user_hash': Cookies.get('crm_funil_hash')
                            };
                            $('.spinner_' + id).toggleClass('hide'); // aparecer loading
                            $('.ganhou_' + id).toggleClass('hide'); // ocultar icone ganhou
                            $('.perdeu_' + id).toggleClass('hide'); // ocultar icone perdeu
                            vm.removerTooltipAcao(id);

                            negociosService.marcarSituacao(dados).then(function(response) {
                                console.log("marcarSituacao");
                                console.log(response.data);
                                //return null;
                                $('.spinner_' + id).toggleClass('hide');
                                $('.ganhou_' + id).toggleClass('hide');
                                $('.perdeu_' + id).toggleClass('hide');

                                if (qual == 1) { // CLICOU NO BOTÃO GANHOU
                                    vm.venderLeadExactSales(id);

                                    if (vm.negocios.ganhou == 1) { // SE JÁ ESTÁ MARCADO COMO 'GANHO', 'ABRE' A NEGOCIAÇÃO
                                        vm.negocios.ganhou = 0;
                                        vm.negocios.perdeu = 0;
                                        vm.negocios.data_ganho_perda = '';
                                    } else { // NÃO ESTÁ MARCADO COMO 'GANHO', 'GANHAR' NEGOCIAÇÃO
                                        vm.negocios.ganhou = 1;
                                        vm.negocios.perdeu = 0;
                                        vm.negocios.data_ganho_perda = moment().format('DD/MM/YYYY');
                                        $('.perdeu_' + id).removeClass('marcado_perdeu');
                                        $('.perdeu_' + id).removeClass('botao_perder_marcado');
                                    }
                                } else { // CLICOU NO BOTÃO PERDER
                                    vm.descartarLeadExactSales(id);

                                    if (vm.negocios.perdeu == 1 || $('.perdeu_' + id).hasClass('marcado_perdeu') || $('.perdeu_' + id).hasClass('botao_perder_marcado')) { // SE JÁ ESTA MARCADO COMO 'PERDIDA', 'ABRE' A NEGOCIAÇÃO
                                        vm.negocios.ganhou = 0;
                                        vm.negocios.perdeu = 0;
                                        vm.negocios.data_ganho_perda = '';

                                        $('.perdeu_' + id).removeClass('marcado_perdeu');
                                        $('.perdeu_' + id).removeClass('botao_perder_marcado');
                                    }
                                }

                                if ($(".perdeu_" + id).hasClass('tooltipstered')) {
                                    $(".perdeu_" + id).tooltipster("destroy");
                                    $timeout(function() {
                                        $(".perdeu_" + id).tooltip({
                                            title: '',
                                            placement: 'top'
                                        });
                                    }, 200);
                                }

                                vm.mostrarTooltipAcao(id);
                                $timeout(function() {
                                    vm.removerTooltipAcao(id);
                                }, 4000);
                            }, function(error) {
                                $log.error(error);
                            });  
                        })
                    }
                }
            }
        };

        vm.mostrarTooltipAcao = function(id) {
            var left = $('.situacao_' + id).offset().left;
            var top = $('.situacao_' + id).offset().top;
            var width = $('.situacao_' + id).width();
            var height = $('.situacao_' + id).height();
            var width_t = $('.acao_' + id).width(); // largura tooltip
            $('.msg_' + id).html('Alterado com sucesso!<br>Clique novamente para desfazer.');
            $('.acao_' + id).css('left', ((width / 2) - (width_t / 2) + 15) + 'px');
            $('.acao_' + id).css('top', (height + 15) + 'px');
            $('.acao_' + id).fadeIn('fast');
        };

        vm.removerTooltipAcao = function(id) {
            $('.acao_' + id).fadeOut('fast');
        };

        vm.editando = false;
        vm.editarNegociacao = function(qual) {
            vm.descontoNegociacao = [];

            vm.buscarHistoriDescNeg(vm.negocios.id)

            if (qual == 'editar') {
                vm.editando = true;
                $('.box_info_negociacao').fadeOut(200);
                $('.box_atividades_negociacao').fadeOut(200);
                $('.botao_editar_novo').fadeOut(200);
                vm.carregardadosNeg(1);
                //vm.aplicarPrazo();
                $timeout(function() {
                    $('.box_cadastro_negocios').fadeIn(200);
                }, 200);
                if (vm.mobile) {
                    $timeout(function() {
                        $('.box_PE').css('width', screen.width - 60 + 'px');
                    });
                }
            } else if (qual == 'cancelar') {
                vm.editando = false;
                $('.box_cadastro_negocios').fadeOut(200);
                vm.carregardadosNeg(0);
                $timeout(function() {
                    $('.box_info_negociacao').fadeIn(200);
                    $('.box_atividades_negociacao').fadeIn(200);
                    $('.botao_editar_novo').fadeIn(200);
                }, 200);
                vm.produto = {
                    'prod_id': '',
                    'prod_nome': ''
                };
                //vm.buscarProdutosProposta(vm.negocios.id);
                vm.buscarProdutosProposta($routeParams.slugNegocio);
                setTimeout(function() {
                    vm.descontoNegociacao = [];
                }, 100);
            }
        };

        /*vm.aplicarPrazo = function() {
            $timeout(function() {
                $('#campo_prazo').daterangepicker({
                    singleDatePicker: true,
                    showDropdowns: true,
                    locale: {
                        format: 'DD/MM/YYYY'
                    }
                });
                $('#campo_prazo').data('daterangepicker').setStartDate(vm.negocios.prazo);
                $('.grafico_interna_cadastro').attr('value', vm.negocios.etapa_id);
            });
        }*/

        vm.responsavel = '';
        vm.url_img = API.url;

        vm.tirarBTN = function() {
            $('span[role=button]').addClass('background_style_btn');
        };

        /*$timeout(function() {
            $('.ui-select-choices-content').niceScroll();
        });*/

        vm.setarIMG = function(id) {
            $timeout(function() {
                vm.rand = (new Date()).toString();
            });
        };

        vm.buscaUsuarios = function(responsavel) {
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) { // ler array de retorno
                vm.saasid = items;
                var dados = {
                    'saasid': vm.saasid,
                    'user_hash': Cookies.get('crm_funil_hash'),
                    'acao': 'ALTERA',
                    'mod_id': 2
                }
                negociosService.findUsuarios(dados).then(function(response) {
                    $timeout(function() {
                        vm.usuarios = [];
                        vm.responsavel = {};
                        vm.usuarios = response.data;
                        $timeout(function() {
                            if (responsavel) {
                                for (var x = 0; x < vm.usuarios.length; x++) {
                                    if (vm.usuarios[x].user_hash == responsavel) {
                                        vm.responsavel = vm.usuarios[x];
                                    }
                                }
                                // FILTRAR USUARIOS ATIVOS
                                vm.usuarios = $filter('filter')(vm.usuarios, {
                                    user_ativo: '1'
                                });
                            }
                        });
                    });
                }, function(error) {
                    $log.error(error);
                });
            });
        };

        vm.fim_repeat = function() {
            $('#spinner_carregar_pessoas_empresas').hide();
        };

        vm.growableOptions = {
            displayText: 'Pessoas/Empresas',
            emptyListText: 'Procurando registros...',
            onAdd: function(texto, qual) {
                var deferred = $q.defer();

                // LIMITAÇÃO DE LEADS --- INÍCIO
                vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
                vm.items.then(function(items) {
                    vm.saasid = items;
                    vm.leads = verificaLeads(vm.saasid); // carregar saas id
                    vm.leads.then(function(info) {
                        if (info.limite <= info.quantia) {
                            $('#modalAlertaLeads').modal({
                                backdrop: 'static',
                                keyboard: false
                            });
                            vm.salvando = false;
                        } else { // PROSEGUIR CADASTRO ----------------------------
                            var dados = {
                                'nome': texto,
                                'saasid': vm.saasid
                            };
                            $('.check_cadastro').hide(); // remove o check
                            $('.spinner_cadastro').toggleClass('hide'); // aparecer loading

                            if (qual == 'p') { // clicou no "cadastrar pessoa"
                                vm.abrirModalCadastroRapido(dados, 'p');
                                $('#modalCadastroRapido').on('hidden.bs.modal', function(e) {
                                    $('#modalCadastroRapido').off();
                                    if (vm.dadosCadRapido.salvar && vm.dadosCadRapido.qual == 'p') {
                                        negociosService.cadastrarPessoaRapido(vm.dadosCadRapido).then(function(response) {
                                            //vm.listarPessoasEmpresas(response.data);
                                            vm.dadosCadastro = response.data;
                                            /*$(".spinner_cadastro").toggleClass("hide"); // remover loading
                                            $(".spinner_cadastro").addClass("hide"); // remover loading
                                            $(".check_cadastro").fadeIn("slow"); // mostra check sucesso
                                            $(".limpar").val(""); // limpar input após cadastro
                                            $timeout(function() {
                                                $(".check_cadastro").fadeOut("slow"); // após 2 segundos remove o check
                                            }, 2000);*/
                                            deferred.resolve(response.data);
                                        }, function(error) {
                                            $log.error(error);
                                        });
                                    } else {
                                        $('.spinner_cadastro').toggleClass('hide'); // remover loading
                                    }
                                });
                            } else if (qual == 'e') { // clicou no "cadastrar empresa"
                                vm.abrirModalCadastroRapido(dados, 'e');
                                $('#modalCadastroRapido').on('hidden.bs.modal', function(e) {
                                    $('#modalCadastroRapido').off();
                                    if (vm.dadosCadRapido.salvar && vm.dadosCadRapido.qual == 'e') {
                                        negociosService.cadastrarEmpresaRapido(vm.dadosCadRapido).then(function(response) {
                                            //vm.listarPessoasEmpresas(response.data);
                                            vm.dadosCadastro = response.data;
                                            /*$(".spinner_cadastro").toggleClass("hide"); // remover loading
                                            $(".spinner_cadastro").addClass("hide"); // remover loading
                                            $(".check_cadastro").fadeIn("slow"); // mostra check sucesso
                                            $timeout(function() {
                                                $(".check_cadastro").fadeOut("slow"); // após 2 segundos remove o check
                                            }, 2000);*/
                                            deferred.resolve(response.data);
                                        }, function(error) {
                                            $log.error(error);
                                        });
                                    } else {
                                        $timeout(function() {
                                            $('.spinner_cadastro').toggleClass('hide'); // remover loading
                                        });
                                    }
                                });
                            }
                        }
                    });
                });
                // LIMITAÇÃO DE LEADS --- FIM
                return deferred.promise;
            }
        };

        vm.asyncPE = function(term) {
            var deferred = $q.defer();
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) { // ler array de retorno
                vm.saasid = items;
                var dados = {
                    'term': term,
                    'saasid': vm.saasid,
                    'user_hash': Cookies.get('crm_funil_hash')
                }
                $timeout(function() {
                    var altura_box = angular.element(".box_menu_lista_registros").height();
                    angular.element(".box_loading_registros").css("height", altura_box + "px");
                    angular.element(".box_loading_registros").css("padding-top", altura_box / 2 + "px");
                    angular.element(".box_lista_registros").hide();
                    angular.element(".box_loading_registros").show();
                });
                negociosService.listarPessoasEmpresas(dados).then(function(response) {
                    /*console.log("listarPessoasEmpresas");
                    console.log(response.data);*/
                    if (response.data != '0') {
                        vm.listaPessoasEmpresas = response.data;
                        if (vm.dadosCadastro) {
                            vm.negocios.cliente_empresa = vm.dadosCadastro;
                            $('.spinner_cadastro').toggleClass('hide'); // remover loading
                            $('.spinner_cadastro').addClass('hide'); // remover loading
                            $('.check_cadastro').fadeIn('slow'); // mostra check sucesso
                            $('.limpar').val(''); // limpar input após cadastro
                            $timeout(function() {
                                $('.check_cadastro').fadeOut('slow'); // após 2 segundos remove o check
                            }, 2000);
                            vm.slugPE = vm.dadosCadastro.slug;
                        }
                    } else {
                        vm.listaPessoasEmpresas = [];
                    }

                    if (vm.listaPessoasEmpresas == '') { // informar mensagem a ser exibida no custom select
                        $('.spinner_pessoa_empresa').hide();
                        $('.listaLimpaTexto').html('Nenhum registro encontrado');
                    } else {
                        $('.spinner_pessoa_empresa').show();
                        $('.listaLimpaTexto').html('Procurando registros...');
                    }
                    deferred.resolve(response.data);
                }, function(error) {
                    $log.error(error);
                });
            }, function(error) {
                $log.error(error);
            });

            deferred.promise.then(function(dados, b) {
                $timeout(function() {
                    angular.element(".box_lista_registros").show();
                    angular.element(".box_loading_registros").hide();
                })
            });

            return deferred.promise;

        }

        /*vm.listaPessoasEmpresas = [];
        vm.listarPessoasEmpresas = function(dadosCadastro) {
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) { // ler array de retorno
                vm.saasid = items;
                var dados = {
                    'saasid': vm.saasid,
                    'user_hash': Cookies.get('crm_funil_hash')
                }
                vm.empresas = [];
                negociosService.listarPessoasEmpresas(dados).then(function(response) {
                    if (response.data != '0') {
                        //vm.dados = response.data;
                        vm.listaPessoasEmpresas = response.data;
                        if (dadosCadastro) {
                            vm.negocios.cliente_empresa = dadosCadastro;
                            $('.spinner_cadastro').toggleClass('hide'); // remover loading
                            $('.spinner_cadastro').addClass('hide'); // remover loading
                            $('.check_cadastro').fadeIn('slow'); // mostra check sucesso
                            $('.limpar').val(''); // limpar input após cadastro
                            $timeout(function() {
                                $('.check_cadastro').fadeOut('slow'); // após 2 segundos remove o check
                            }, 2000);
                        }
                    } else {
                        vm.empresas = [];
                    }
                }, function(error) {
                    $log.error(error);
                });
            });
        };
        vm.listarPessoasEmpresas();*/


        /*CONFIGURAÇÕES MODAL CADASTRO RAPIDO --- INICIO*/


        vm.empresasRepeat = [];
        vm.listarEmpresas = function() {
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) {
                vm.saasid = items;
                var dados = {
                    'saasid': vm.saasid
                };
                vm.empresas = [];
                PessoasService.listarEmpresas(dados).then(function(response) {
                    if (response.data != '0') {
                        vm.empresas = response.data;
                    } else {
                        vm.empresas = [];
                    }
                    vm.empresasRepeat.push({
                        'icon': '<i class=\'fa fa-times\'></i>',
                        'id': '',
                        'name': 'Nenhuma empresa',
                        'ticked': ''
                    });
                    for (var x = 0; x < vm.empresas.length; x++) {
                        vm.empresasRepeat.push({
                            'id': vm.empresas[x].emp_id,
                            'name': vm.empresas[x].emp_nome,
                            'ticked': false
                        });
                    }
                }, function(error) {
                    $log.error(error);
                });
            });
        };

        vm.listarCargos = function() {
            vm.cargosRepeat = [];
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) {
                vm.saasid = items;
                var dados = {
                    'saasid': vm.saasid
                };
                PessoasService.listarCargos(dados).then(function(response) {
                    vm.cargosRepeat.push({
                        'icon': '<i class=\'fa fa-times\'></i>',
                        'name': 'Nenhum cargo',
                        'ticked': false,
                        'id': 'nenhum'
                    });
                    if (response.data != '0') {
                        angular.forEach(response.data, function(value, key) {
                            vm.cargosRepeat.push({
                                'name': value.car_nome,
                                'ticked': false,
                                'id': value.car_id
                            });
                        });
                    } else {
                        vm.cargosRepeat = [];
                    }
                }, function(error) {
                    $log.error(error);
                });
            });
        };

        /*ORIGEM DO CONTATO --- INICIO*/

        vm.growableOptions5 = {
            displayText: 'Selecione a origem',
            emptyListText: 'Nenhuma origem cadastrada',
            onAdd: function(texto) {
                var deferred = $q.defer();
                // LIMITAÇÃO DE LEADS --- INÍCIO
                vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
                vm.items.then(function(items) {
                    vm.saasid = items;
                    var dados = {
                        'nome': texto,
                        'saasid': vm.saasid
                    };
                    $('.check_cadastro_origem').hide(); // remove o check
                    $('.spinner_cadastro_origem').toggleClass('hide'); // aparecer loading
                    origemContatoService.cadastrarOrigemContatoRapido(dados).then(function(response) {
                        if (response.data[0].orc_id) {
                            vm.listarOrigem();
                            $('.spinner_cadastro_origem').toggleClass('hide'); // remover loading
                            $('.check_cadastro_origem').fadeIn('slow'); // mostra check sucesso
                            $('.limpar').val(''); // limpar input após cadastro
                            $timeout(function() {
                                $('.check_cadastro_origem').fadeOut('slow'); // após 2 segundos remove o check
                            }, 2000);
                            var origem = {
                                'orc_id': response.data[0].orc_id,
                                'orc_nome': response.data[0].orc_nome
                            };
                        }
                        deferred.resolve(origem);
                    }, function(error) {
                        $log.error(error);
                    });
                });
                return deferred.promise;
            }
        };

        vm.origemRepeat = [];
        vm.listarOrigem = function() {
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) {
                vm.saasid = items;
                var dados = {
                    'saasid': vm.saasid
                };
                vm.empresas = [];
                origemContatoService.findAll(dados).then(function(response) {
                    if (response.data != '0') {
                        vm.origemRepeat = response.data;
                    } else {
                        vm.origemRepeat = [];
                    }
                }, function(error) {
                    $log.error(error);
                });
            });
        };

        vm.limparOrigem = function() {
            vm.dadosCadRapido.origem = {
                'orc_id': '',
                'orc_nome': ''
            };
            if (vm.executando == false) {
                $('#msgOrigem').fadeIn(200);
                vm.executando = true;
                $timeout(function() {
                    $('#msgOrigem').fadeOut(200);
                    vm.executando = false;
                }, 2000);
            }
        };

        /*ORIGEM DO CONTATO --- FIM*/

        vm.abrirModalCadastroRapido = function(dados, qual) {
            vm.dadosCadRapido = dados;
            vm.dadosCadRapido.salvar = false;
            vm.dadosCadRapido.qual = qual;
            vm.dadosCadRapido.categoria = '0';
            vm.dadosCadRapido.email = '';
            vm.dadosCadRapido.telefone = 55;
            vm.dadosCadRapido.telefone2 = 55;
            vm.dadosCadRapido.cargo = '';
            vm.dadosCadRapido.origem = '';
            vm.dadosCadRapido.user_hash = Cookies.get('crm_funil_hash');
            vm.validarCadRapido = {
                'nome': false
            };
            vm.listarEmpresas();
            vm.listarCargos();
            vm.listarOrigem();
            $('#modalCadastroRapido').modal({
                backdrop: 'static',
                keyboard: false,
                show: true
            }).on('shown.bs.modal', function(e) {
                $('#campo_nome_rapido').focus();
            });
        };

        vm.aplicarScroll = function() {
            $('.checkBoxContainer').niceScroll();
        };

        vm.buscaCategorias = function() {
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) { // ler array de retorno
                vm.saasid = items;
                PessoasService.findCategorias(vm.saasid).then(function(response) {
                    vm.categorias = response.data;
                }, function(error) {
                    $log.error(error);
                });
            });
        };

        vm.ptbr = {
            selectAll: 'Todos',
            selectNone: 'Limpar',
            search: 'Pesquisar...',
            nothingSelected: 'Empresas'
        };
        vm.ptbr2 = {
            selectAll: 'Todos',
            selectNone: 'Limpar',
            search: 'Pesquisar...',
            nothingSelected: 'Cargos'
        };

        vm.efetuarCadastroRapido = function() {
            if (vm.dadosCadRapido.email != '') { // EMAIL PREENCHIDO, VALIDAR EMAIL
                var enviar = {
                    'email': vm.dadosCadRapido.email,
                    'saasid': vm.saasid
                };
                PessoasService.validaExisteEmail(enviar).then(function(response) {
                    if (response.data != 0) { // existe cliente com o email
                        if (response.data[0].tipo == 'p') {
                            swal({
                                title: 'E-mail já existente!',
                                text: 'Já existe um cliente cadastrado com o email informado:<br><br><a href=\'/pessoas/emailsAdd/info/' + response.data[0].slug + '\' target=\'_blank\' class=\'nome_cliente_existe_email\'>' +
                                    response.data[0].nome + '</a>',
                                type: 'warning',
                                showConfirmButton: true,
                                html: true
                            }, function() {
                                $timeout(function() {
                                    $('#email').focus();
                                });
                            });
                        } else if (response.data[0].tipo == 'e') {
                            swal({
                                title: 'E-mail já existente!',
                                text: 'Já existe uma empresa cadastrada com o email informado:<br><br><a href=\'/empresas/emailsAdd/info/' + response.data[0].slug + '\' target=\'_blank\' class=\'nome_cliente_existe_email\'>' +
                                    response.data[0].nome + '</a>',
                                type: 'warning',
                                showConfirmButton: true,
                                html: true
                            }, function() {
                                $timeout(function() {
                                    $('#email').focus();
                                });
                            });
                        }
                    } else { // não existe cliente com o email
                        vm.dadosCadRapido.salvar = true;
                        $('#modalCadastroRapido').modal('hide');
                    }
                }, function(error) {
                    $log.error(error);
                });
            } else { // EMAIL NÃO PREENCHIDO, CONTINUAR SEM VALIDAÇÃO
                vm.dadosCadRapido.salvar = true;
                $('#modalCadastroRapido').modal('hide');
            }
        };


        /*CONFIGURAÇÕES MODAL CADASTRO RAPIDO --- FIM*/

        //permite o filter funcionar sem ser necessário digitar os acentos para filtrar pela palavra com acentos
        vm.searchFn = function(actual, expected) {
            if (angular.isObject(actual)) return false;

            function removeAccents(value) {
                //substitui os acentos por letras normais
                return value.toString()
                    .replace(/á/g, 'a')
                    .replace(/à/g, 'a')
                    .replace(/ã/g, 'a')
                    .replace(/ä/g, 'a')
                    .replace(/â/g, 'a')
                    .replace(/é/g, 'e')
                    .replace(/è/g, 'e')
                    .replace(/ê/g, 'e')
                    .replace(/ë/g, 'e')
                    .replace(/í/g, 'i')
                    .replace(/ï/g, 'i')
                    .replace(/ì/g, 'i')
                    .replace(/î/g, 'i')
                    .replace(/ó/g, 'o')
                    .replace(/ô/g, 'o')
                    .replace(/õ/g, 'o')
                    .replace(/ö/g, 'o')
                    .replace(/ò/g, 'o')
                    .replace(/ú/g, 'u')
                    .replace(/ü/g, 'u')
                    .replace(/û/g, 'u')
                    .replace(/ù/g, 'u')
                    /*.replace(/ç/g, 'c')*/
                    .replace(/ß/g, 's');
            }
            actual = removeAccents(angular.lowercase('' + actual));
            expected = removeAccents(angular.lowercase('' + expected));

            return actual.indexOf(expected) !== -1;
        };

        vm.marcar_situacao_cadastro = function(qual) {
            if (qual == 1) { // clicou em ganhou
                if ($('.ganhou_cadastro').hasClass('marcado_ganhou')) { // verifica se jah existe a classe 'marcado ganhou'
                    $('.ganhou_cadastro').removeClass('marcado_ganhou'); // faz a troca da classe marcado no icone 'ganhou'
                    vm.negocios.ganhou = 0;
                    vm.negocios.data_ganho_perda = '';
                } else {
                    $('.ganhou_cadastro').addClass('marcado_ganhou'); // faz a troca da classe marcado no icone 'ganhou'
                    vm.negocios.ganhou = 1;
                    vm.negocios.perdeu = 0;
                    vm.negocios.data_ganho_perda = moment().format('DD/MM/YYYY');
                }
                $('.perdeu_cadastro').removeClass('marcado_perdeu'); // remove a classe marcado do icone 'perdeu' caso esteja marcado
            } else { // clicou em perdeu
                if ($('.perdeu_cadastro').hasClass('marcado_perdeu')) { // verifica se jah existe a classe 'marcado perdeu'
                    $('.perdeu_cadastro').removeClass('marcado_perdeu'); // remove classe marcado do icone 'perdeu'
                    vm.negocios.perdeu = 0;
                    vm.negocios.data_ganho_perda = '';
                } else {
                    $('.perdeu_cadastro').addClass('marcado_perdeu'); // remove a classe marcado do icone 'perdeu'
                    vm.negocios.perdeu = 1;
                    vm.negocios.ganhou = 0;
                    vm.negocios.data_ganho_perda = moment().format('DD/MM/YYYY');
                }
                $('.ganhou_cadastro').removeClass('marcado_ganhou');
            }
        };

        vm.salvarNegocio = function() {

            function continuarSalvando() {
                vm.salvando = true;

                vm.salvarComoHistoricoDescontoNeg = []
                // vm.TotalLiquidAfter = freezGF(vm.getTotalLiquidNeg);
                if (vm.getTotalLiquidNeg < 0) {
                    vm.TotalLiquidAfter = '0';
                } else {
                    vm.TotalLiquidAfter = vm.getTotalLiquidNeg;
                }

                // Inicio - verifica se tem desconto para salvar
                if (vm.descontoNegociacao) {
                    if (vm.descontoNegociacao.length) {
                        for (var i = 0; i < vm.descontoNegociacao.length; i++) {
                            if (!vm.descontoNegociacao[i].propdesc_id) {
                                negociosService.adiciona_desc_negociacao({
                                    "fk_prop_id": vm.descontoNegociacao[i].fk_prop_id,
                                    "propdesc_is_porc": vm.descontoNegociacao[i].propdesc_is_porc ? 1 : 0,
                                    "propdesc_valor": vm.descontoNegociacao[i].propdesc_valor,
                                    "propdesc_desconto": vm.descontoNegociacao[i].propdesc_desconto
                                }).then(function(response) {
                                    $timeout(function() {
                                        // console.log(response)
                                    });
                                }, function(error) {
                                    $log.error(error);
                                });
                            }
                            if (i == vm.descontoNegociacao.length - 1) {
                                $timeout(function() {
                                    vm.descontoNegociacao = [];
                                }, 1500);
                            }
                        }
                        vm.descontoNegociacao.map(function(e) {
                            e.propdesc_is_porc = e.propdesc_is_porc == 1 ? 1 :
                                e.propdesc_is_porc == true ? 1 :
                                e.propdesc_is_porc == 0 ? 0 :
                                e.propdesc_is_porc == false ? 0 :
                                ''

                        })
                        vm.salvarComoHistoricoDescontoNeg.push(vm.descontoNegociacao);
                    }
                }
                // Fim - verifica se tem desconto para salvar

                // Inicio - verifica se tem desconto para desativar no BD
                if (vm.descontoNegociacaoUpdateBanco) {
                    if (vm.descontoNegociacaoUpdateBanco.length) {
                        for (var i = 0; i < vm.descontoNegociacaoUpdateBanco.length; i++) {
                            if (vm.descontoNegociacaoUpdateBanco[i].propdesc_id) {
                                negociosService.deletarHistoriDescNeg(vm.descontoNegociacaoUpdateBanco[i].propdesc_id).then(function(response) {
                                    // console.log(response)
                                }, function(error) {
                                    $log.error('Erro: ', error);
                                });
                            }
                            if (i == vm.descontoNegociacaoUpdateBanco.length - 1) {
                                vm.descontoNegociacaoUpdateBanco = [];
                            }
                        }
                    }
                }
                // Final - verifica se tem desconto para desativar no BD

                vm.mandarBDAtiviReg = []
                var BDHistoricoBeforeDesc = {}
                var _localUserStorage = window.localStorage.getItem('5c479de2');
                var _ValueLocalUserStorage = JSON.parse(API.funilRC.decrypt(_localUserStorage));
                // index
                BDHistoricoBeforeDesc['afterDesc'] = []
                BDHistoricoBeforeDesc['beforeDesc'] = []
                BDHistoricoBeforeDesc['afterTotalLiquido'] = []
                BDHistoricoBeforeDesc['beforeTotalLiquido'] = []
                //push
                BDHistoricoBeforeDesc['beforeDesc'].push(vm.historBeforeDesc ? vm.historBeforeDesc : null)
                BDHistoricoBeforeDesc['afterDesc'].push(vm.descontoNegociacao)
                BDHistoricoBeforeDesc['afterTotalLiquido'].push(vm.TotalLiquidAfter ? vm.TotalLiquidAfter : null)
                BDHistoricoBeforeDesc['beforeTotalLiquido'].push(vm.TotalLiquidBefore ? vm.TotalLiquidBefore : null)


                // descontos
                var _descA = '';
                var _descB = '';
                if (BDHistoricoBeforeDesc.afterDesc[0]) {
                    for (var i = 0; i < BDHistoricoBeforeDesc.afterDesc[0].length; i++) {
                        var valtempDescTxtConcat = '';

                        if (BDHistoricoBeforeDesc.afterDesc[0][i].propdesc_is_porc == 0) {
                            valtempDescTxtConcat = parseFloat(BDHistoricoBeforeDesc.afterDesc[0][i].propdesc_desconto).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
                        } else {
                            valtempDescTxtConcat = parseFloat(BDHistoricoBeforeDesc.afterDesc[0][i].propdesc_desconto).toLocaleString('pt-BR', { style: 'percent', minimumFractionDigits: 2 });
                        }
                        _descA = _descA + valtempDescTxtConcat + '<br/>';
                        setTimeout(function() {
                            valtempDescTxtConcat = '';
                        }, 10);
                    }
                }

                if (BDHistoricoBeforeDesc.beforeDesc[0]) {
                    for (var i = 0; i < BDHistoricoBeforeDesc.beforeDesc[0].length; i++) {
                        var valtempDescTxtConcat = '';
                        if (BDHistoricoBeforeDesc.beforeDesc[0][i].propdesc_is_porc == 0) {
                            valtempDescTxtConcat = parseFloat(BDHistoricoBeforeDesc.beforeDesc[0][i].propdesc_desconto).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });;
                        } else {
                            valtempDescTxtConcat = parseFloat(BDHistoricoBeforeDesc.beforeDesc[0][i].propdesc_desconto).toLocaleString('pt-BR', { style: 'percent', minimumFractionDigits: 2 });
                        }
                        _descB = _descB + valtempDescTxtConcat + '<br/>';

                        setTimeout(function() {
                            valtempDescTxtConcat = '';
                        }, 10);
                    }
                }


                if (_descB != _descA) {
                    vm.mandarBDAtiviReg.push({
                        'propati_nome_campo': 'dados_desconto',
                        'propati_antigo': _descB ? _descB : '-',
                        'propati_novo': _descA ? _descA : '-',
                    })
                }



                // valores totais
                if (BDHistoricoBeforeDesc.afterTotalLiquido.length && BDHistoricoBeforeDesc.beforeTotalLiquido.length) {
                    var _vA = parseFloat(BDHistoricoBeforeDesc.afterTotalLiquido["0"])
                    var _vB = parseFloat(BDHistoricoBeforeDesc.beforeTotalLiquido["0"])

                    if (_vA != _vB) {
                        vm.mandarBDAtiviReg.push({
                            'propati_nome_campo': 'dados_valor',
                            'propati_antigo': _vB,
                            'propati_novo': _vA,
                        })

                    }
                }



                var validar = 0;
                // VALIDAÇÃO --- INÍCIO
                if (vm.negocios.nome == '') {
                    vm.validar.nome = true;
                    validar = 1;
                    vm.salvando = false;
                } else {
                    vm.validar.nome = false;
                }
                if (vm.negocios.cliente_empresa == '') {
                    vm.validar.cliente_empresa = true;
                    validar = 1;
                    vm.salvando = false;
                } else {
                    vm.validar.cliente_empresa = false;
                }
                /*if (vm.negocios.valor == '') {
                    vm.validar.valor = true;
                    validar = 1;
                    vm.salvando = false;
                } else {
                    vm.validar.valor = false;
                }*/
                if (vm.negocios.prazo == '') {
                    vm.validar.prazo = true;
                    validar = 1;
                    vm.salvando = false;
                } else {
                    vm.validar.prazo = false;
                }

                if (vm.negocios.responsavel == '') {
                    vm.validar.responsavel = true;
                    validar = 1;
                    vm.salvando = false;
                } else {
                    vm.validar.responsavel = false;
                }

                // VALIDAÇÃO --- FIM
                if (validar == 0) {
                    vm.negocios.qual_selecionado = $('#pessoa_empresa_selecionado').attr('value');

                    vm.salvando = true;
                    angular.element('#submit_negocio').html('<div class="spinner"></div>');
                    angular.element('#submit_negocio_responsivo').html('<div class="spinner"></div>');

                    vm.executarUpdate();
                }
            }

            if (vm.produto.prod_id) {
                swal({
                        title: 'Atenção!',
                        text: 'Verificamos que um produto foi selecionado, mas ainda não está adicionado a sua lista para ser salvo. Deseja continuar mesmo assim?',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#DD6B55',
                        confirmButtonText: 'Continuar',
                        cancelButtonText: 'Cancelar',
                        closeOnConfirm: true
                    },
                    function() {
                        continuarSalvando();
                    });
            } else {
                continuarSalvando();
            }


        };

        vm.integrarCompufacilEdit = function(id) {
            var dados = {
                'saasid': vm.saasid,
                'id_prop': id,
                'editando_neg': true,
                'user_hash': Cookies.get('crm_funil_hash')
            }
            negociosService.integrarCompufacil(dados).then(function(response) {
                $timeout(function() {
                    // console.log("**** integrarCompufacilEdit ****");
                    // console.log(response.data);
                    vm.carregarHistorico(id);
                });
            }, function(error) {
                $log.error(error);
            });
        }

        vm.descartarLeadExactSalesEdit = function(id) {
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) { // ler array de retorno
                vm.saasid = items;
                var dados = {
                    'saasid': vm.saasid,
                    'id_prop': id,
                    'editando_neg': true
                }
                negociosService.descartarLeadExactSales(dados).then(function(response) {
                    /*console.log("descartarLeadExactSales");
                    console.log(response.data);*/
                    vm.carregarHistorico(id);
                }, function(error) {
                    $log.error(error);
                });
            });
        }

        vm.triggerWebhookAlter = function(idProposta) {
            // get token funil
            var BaseApi = APINode.url + 'token/';
            var userAPI = JSON.parse(RC.funilRC.decrypt(window.localStorage.getItem('5c479de2')));
            var localSaas = userAPI['0'].saas_id;
            var urlEndpoint = BaseApi + localSaas;

            $http.get(urlEndpoint).then(function(res) {
                if (res.data.data) {

                    var staticTokenFunil = res.data.data.api_token;
                    var idNegWebHook = idProposta;

                    //get existe event or not
                    $http.get(APINode.url + 'webhook/consulta/negalter', {
                            headers: {
                                "TokenFunil": staticTokenFunil
                            }
                        })
                        .then(function(responseG) {
                            // verifica se existe evento cadastrado
                            if (responseG.data.existe) {

                                var objEnviar = {
                                    "idNegocicao": idNegWebHook
                                }

                                $http({
                                    method: 'POST',
                                    url: APINode.url + "webhook/trigger/negalter",
                                    data: objEnviar,
                                    headers: {
                                        "TokenFunil": staticTokenFunil,
                                        'Content-Type': 'application/json',
                                        'Accept': 'application/json'
                                    },
                                    timeout: 4000
                                }).then(function(success) {
                                    console.log(success);
                                }, function(error) {
                                    console.log(error);
                                });
                            } else {
                                console.log('evento "NEGALTER" não cadastrado ');
                            }
                        })
                }
            });

        }

        vm.executarUpdate = function() {
            vm.negocios.responsavel = vm.responsavel.user_id;
            vm.negocios.user_hash = Cookies.get('crm_funil_hash');
            vm.negocios.saasid = vm.saasid;
            vm.negocios.prop_valor_total = vm.getTotalLiquidNeg;
            vm.negocios.diffValuesNegDesc = vm.mandarBDAtiviReg;
            vm.negocios.prop_contrato = vm.contrato;
            vm.negocios.prop_pagamento_online = vm.pagOnline;

            if (vm.produtosAdd != '') {
                vm.negocios.produtos = vm.produtosAdd;
            } else {
                vm.negocios.produtos = '';
            }

            if (vm.negocios.prop_valor_total < 0) {
                vm.negocios.prop_valor_total = 0;
            }

            negociosService.editarNegocio(vm.negocios).then(function(response) {
                console.log("--------------- editarNegocio ---------------");
                console.log(response.data);
                /*vm.salvando = false;
                return null;*/
                //vm.triggerWebhookAlter(vm.negocios.id);
                FactoryWebhook.triggerUpdate(vm.negocios.id);
                vm.buscarHistoriDescNeg(vm.negocios.id, 'index')


                /*vm.salvando = false;
                return null;*/
                swal({
                    title: 'Alterado com sucesso!',
                    type: 'success',
                    text: 'Proposta alterada com sucesso!',
                    showConfirmButton: false,
                    timer: 1000
                });
                $timeout(function() {
                    var dados = {
                        'qual': vm.negocios.qual_selecionado,
                        'slug': response.data
                    };
                    negociosService.carregaSlug(dados).then(function(responsee) {
                        if (responsee.data != $routeParams.slugCliente) {
                            if (vm.negocios.qual_selecionado == 'p') {
                                $location.url('/pessoas/' + responsee.data + '/negocio/info/' + response.data);

                                var uri = API.url + '/pessoas/' + responsee.data + '/negocio/info/' + response.data;
                                var respNotifId = vm.negocios.responsavel;
                                var localUs = API.funilRC.decrypt($window.localStorage.getItem('5c479de2'));
                                var localUdParse = JSON.parse(localUs);
                                var userLogId = localUdParse['0'].user_id;
                                var userLognome = localUdParse['0'].user_nome;

                                if (respNotifId != userLogId) {
                                    // dispara a factory criar notificação
                                    FactoryNotificacao.createNotificacao({
                                        "idUser": respNotifId,
                                        "origem": userLognome,
                                        "mensagem": 'alteraNeg',
                                        "link": uri
                                    })
                                }

                            } else if (vm.negocios.qual_selecionado == 'e') {
                                $location.url('/empresas/' + responsee.data + '/negocio/info/' + response.data);

                                var uri = API.url + '/empresas/' + responsee.data + '/negocio/info/' + response.data;
                                var respNotifId = vm.negocios.responsavel;
                                var localUs = API.funilRC.decrypt($window.localStorage.getItem('5c479de2'));
                                var localUdParse = JSON.parse(localUs);
                                var userLogId = localUdParse['0'].user_id;
                                var userLognome = localUdParse['0'].user_nome;

                                if (respNotifId != userLogId) {
                                    // dispara a factory criar notificação
                                    FactoryNotificacao.createNotificacao({
                                        "idUser": respNotifId,
                                        "origem": userLognome,
                                        "mensagem": 'alteraNeg',
                                        "link": uri
                                    })
                                }
                            }
                        } else {
                            if (vm.negocios.slug != response.data) {
                                $location.url('/pessoas/' + $routeParams.slugCliente + '/negocio/info/' + response.data);

                                var uri = API.url + '/pessoas/' + $routeParams.slugCliente + '/negocio/info/' + response.data;
                                var respNotifId = vm.negocios.responsavel;
                                var localUs = API.funilRC.decrypt($window.localStorage.getItem('5c479de2'));
                                var localUdParse = JSON.parse(localUs);
                                var userLogId = localUdParse['0'].user_id;
                                var userLognome = localUdParse['0'].user_nome;

                                if (respNotifId != userLogId) {
                                    // dispara a factory criar notificação
                                    FactoryNotificacao.createNotificacao({
                                        "idUser": respNotifId,
                                        "origem": userLognome,
                                        "mensagem": 'alteraNeg',
                                        "link": uri
                                    })
                                }
                            } else {
                                vm.carregardadosNeg(0);
                                vm.editando = false;
                                $('.box_cadastro_negocios').fadeOut(200);
                                $timeout(function() {
                                    $('.box_info_negociacao').fadeIn(200);
                                    $('.box_atividades_negociacao').fadeIn(200);
                                    $('.botao_editar_novo').fadeIn(200);
                                    $('.botao_editar_novo_mob').fadeIn(200);
                                }, 200);
                                vm.salvando = false;
                                // vm.integrarCompufacilEdit(vm.negocios.id);
                                vm.descartarLeadExactSalesEdit(vm.negocios.id);

                                if (vm.negocios.qual_selecionado == 'p') {
                                    var uri = API.url + '/pessoas/' + $routeParams.slugCliente + '/negocio/info/' + $routeParams.slugNegocio;
                                    var respNotifId = vm.negocios.responsavel;
                                    var localUs = API.funilRC.decrypt($window.localStorage.getItem('5c479de2'));
                                    var localUdParse = JSON.parse(localUs);
                                    var userLogId = localUdParse['0'].user_id;
                                    var userLognome = localUdParse['0'].user_nome;

                                    if (respNotifId != userLogId) {
                                        // dispara a factory criar notificação
                                        FactoryNotificacao.createNotificacao({
                                            "idUser": respNotifId,
                                            "origem": userLognome,
                                            "mensagem": 'alteraNeg',
                                            "link": uri
                                        })
                                    }

                                } else if (vm.negocios.qual_selecionado == 'e') {
                                    //var uri = API.url + '/empresas/' + responsee.data + '/negocio/info/' + response.data;
                                    var uri = '';
                                    var respNotifId = vm.negocios.responsavel;
                                    var localUs = API.funilRC.decrypt($window.localStorage.getItem('5c479de2'));
                                    var localUdParse = JSON.parse(localUs);
                                    var userLogId = localUdParse['0'].user_id;
                                    var userLognome = localUdParse['0'].user_nome;

                                    if (respNotifId != userLogId) {
                                        // dispara a factory criar notificação
                                        FactoryNotificacao.createNotificacao({
                                            "idUser": respNotifId,
                                            "origem": userLognome,
                                            "mensagem": 'alteraNeg',
                                            "link": uri
                                        })
                                    }

                                }
                            }
                        }
                    }, function(error) {
                        $log.error(error);
                    });
                }, 1000);
            }, function(error) {
                $log.error(error);
            });
        };

        vm.verificarExibicao = function(qual) {
            if (qual.match(/dados/)) {
                return true;
            }
        };


        vm.excluirNegocio = function() {
            var dados = {
                'slug': $routeParams.slugNegocio
            };
            swal({
                    title: 'Deseja excluir este registro?',
                    text: 'Esta ação é irreversível',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#DD6B55',
                    confirmButtonText: 'Sim',
                    cancelButtonText: 'Não',
                    closeOnConfirm: false
                },
                function() {
                    $window.onkeydown = null;
                    $window.onfocus = null;
                    negociosService.apagarNegocio(dados).then(function(response) {
                        swal({
                            title: 'Removido!',
                            text: 'Negócio removido com sucesso!',
                            type: 'success',
                            showConfirmButton: false,
                            timer: 1000
                        });
                        $timeout(function() {
                            //$location.url('/pessoas/negocio/' + $routeParams.slugCliente);
                            if ($window.history.length > 1) {
                                $window.history.back();
                            } else {
                                $location.url('/pessoas/negocio/' + $routeParams.slugCliente);
                            }
                        }, 1000);
                    }, function(error) {
                        $log.error(error);
                    });
                });
        };

        vm.listarInteracoes = function(prop_id) {
            var dados = {
                'prop_id': prop_id
            };
            negociosService.listarInteracoesNeg(dados).then(function(response) {
                if (response.data != 0 || response.data != '') {
                    vm.listaInteracoes = response.data;
                } else {
                    vm.listaInteracoes = '';
                }
            }, function(error) {
                $log.error(error);
            });
        };

        vm.toggle = function(class_id, index) {
            if ($('#' + class_id + '' + index).is(':visible')) {
                $('#' + class_id + '' + index).css('display', 'none');
                $('#gigar_' + class_id + '' + index).animate({
                    borderSpacing: 0
                }, {
                    step: function(now, fx) {
                        $(this).css('-webkit-transform', 'rotate(' + now + 'deg)');
                        $(this).css('-moz-transform', 'rotate(' + now + 'deg)');
                        $(this).css('transform', 'rotate(' + now + 'deg)');
                    },
                    duration: 1
                }, 'linear');
            } else {
                $('#' + class_id + '' + index).fadeIn(300);
                $('#gigar_' + class_id + '' + index).animate({
                    borderSpacing: 90
                }, {
                    step: function(now, fx) {
                        $(this).css('-webkit-transform', 'rotate(' + now + 'deg)');
                        $(this).css('-moz-transform', 'rotate(' + now + 'deg)');
                        $(this).css('transform', 'rotate(' + now + 'deg)');
                    },
                    duration: 1
                }, 'linear');
            }
        };

        vm.carregarFechado = function() {
            $timeout(function() {
                $('.formatacao_text').css('display', 'none');
                $('.tamanho_font_icon').animate({
                    borderSpacing: 0
                }, {
                    step: function(now, fx) {
                        $(this).css('-webkit-transform', 'rotate(' + now + 'deg)');
                        $(this).css('-moz-transform', 'rotate(' + now + 'deg)');
                        $(this).css('transform', 'rotate(' + now + 'deg)');
                    },
                    duration: 1
                }, 'linear');
                $timeout(function() {
                    for (var x = 0; x < vm.listaInteracoes.length; x++) {
                        var id = vm.listaInteracoes[x].user_id;
                        var t = x;
                        $('#' + id + '' + t).css('display', 'block');
                        $('#gigar_' + id + '' + t).animate({
                            borderSpacing: 90
                        }, {
                            step: function(now, fx) {
                                $(this).css('-webkit-transform', 'rotate(' + now + 'deg)');
                                $(this).css('-moz-transform', 'rotate(' + now + 'deg)');
                                $(this).css('transform', 'rotate(' + now + 'deg)');
                            },
                            duration: 1
                        }, 'linear');
                    }
                }, 1);
                for (var x = 0; x < vm.listaInteracoes.length; x++) {
                    var id = vm.listaInteracoes[x].user_id;
                    var descricao = vm.listaInteracoes[x].descricao;
                    var largura = $('#' + id + '' + x).width();
                    $('#' + id + '' + x).width(largura + 'px');
                    $('#' + id + '' + x).html(descricao);
                }
            }, 1);
        };

        vm.limiteInteracao = 5;
        vm.acabouInteracao = function() {
            vm.carregando_mais_atendimento = false;
            $('.descricao_atendimento').removeClass('disable_a');
            vm.carregarFechado();
            if (vm.limiteInteracao >= vm.listaInteracoes.length) {
                $('.mais_atendimento').fadeOut(100);
            } else {
                $('.mais_atendimento').fadeIn(100);
            }
            vm.atdPg = false;
            if (vm.cliPg == false && vm.textarea == false) {
                vm.carregarPg = false;
            }
        };


        vm.ativarTextarea = function(x) {
            $timeout(function() {
                if (x == 0) {
                    //vm.carregandoTinyInteracao = true;
                    $('.ativar_textarea2').fadeOut(100);
                    //tinyMCE.execCommand('mceRemoveEditor', true, 'nova_interacao');
                    $timeout(function() {
                        $('.ativar_btn_novo_atendimento').fadeOut(100);
                        $('.ativar_textarea').fadeIn(500);
                        /*$('#textarea').html('<textarea name="nova_interacao" id="nova_interacao"></textarea>');
                        tinymce.init({
                            selector: '#nova_interacao',
                            statusbar: false,
                            menubar: false,
                            resize: true,
                            toolbar1: 'undo redo | fontselect fontsizeselect | bold italic underline | strikethrough inline | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent',
                            auto_focus: 'nova_interacao',
                            entity_encoding: 'raw',
                            init_instance_callback: function(editor) {
                                $timeout(function() {
                                    console.log("Renderizou TinyMCE");
                                    vm.carregandoTinyInteracao = false;
                                });
                            }
                        });*/
                        $('#nova_interacao').trumbowyg('destroy');
                        $('#textarea').html('<textarea name="nova_interacao" id="nova_interacao"></textarea>');
                        $('#nova_interacao').trumbowyg({
                            svgPath: '/app/plugins/trumbowyg/dist/ui/icons.svg',
                            btns: [
                                ['undo', 'redo'],
                                ['formatting'],
                                ['strong', 'em', 'underline'],
                                ['foreColor', 'backColor'],
                                ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
                                ['unorderedList', 'orderedList'],
                                ['horizontalRule'],
                                ['removeformat'],
                            ],
                            lang: 'pt_br',
                        });
                        $timeout(function() {
                            $('.trumbowyg-editor').focus();
                        }, 500);
                        /*$timeout(function () {
                            if ($location.url().substring(0, 21) == '/pessoas/atendimento/') {
                                vm.carregarPg = false;
                            }
                        });*/
                    });
                } else {
                    $('.ativar_textarea').fadeOut(100);
                    $timeout(function() {
                        $('.ativar_btn_novo_atendimento').fadeIn(500);
                        $('#textarea').html('');
                        $('#alterar_interacao').trumbowyg('destroy');
                        $('#nova_interacao').trumbowyg('destroy');
                    }, 100);
                    $('.mensagem_erro').fadeOut(100);
                }
            }, 200);
            $timeout(function() {
                vm.textarea = false;
                if (vm.cliPg == false && vm.atdPg == false) {
                    vm.carregarPg = false;
                }
            });
            $timeout(function() {
                var largura = $("#textarea").width();
                $('#textarea').width(largura + 'px');
            }, 400);
        };
        vm.salvandoInt = false;
        vm.salvarNovaInteracao = function() {

            FactoryWebhook.triggerUpdate(vm.negocios.id);

            vm.salvandoInt = true;
            //var texto = tinyMCE.get('nova_interacao').getContent();
            var texto = $('#nova_interacao').trumbowyg('html');
            if (texto != '') {
                $('.mensagem_erro').fadeOut(100);
                angular.element('.salvando_atendimento').html('<div class="spinner"></div>');
                var dados = {
                    'id': vm.negocios.id,
                    'user_hash': Cookies.get('crm_funil_hash'),
                    'descricao': texto
                };

                negociosService.cadastrarInteracao(dados).then(function(response) {
                    //tinyMCE.activeEditor.setContent('');
                    $('#nova_interacao').trumbowyg('empty');
                    vm.listarInteracoes(vm.negocios.id);
                    vm.ativarTextarea(1);
                    angular.element('.salvando_atendimento').html('Salvar');
                    vm.salvandoInt = false;
                }, function(error) {
                    $log.error(error);
                });
            } else {
                vm.salvandoInt = false;
                $('.mensagem_erro').fadeIn(100);
            }
        };

        vm.salvandoAtd = false;
        vm.atdID = '';
        vm.atdDescricao = '';
        //tinyMCE.execCommand('mceRemoveEditor', true, 'alterar_interacao');
        vm.alterarInteracao = function(atd, descricao) {
            vm.atdID = atd;
            vm.atdDescricao = descricao;
            $timeout(function() {
                /*tinyMCE.execCommand('mceRemoveEditor', true, 'alterar_interacao');
                $('#textareaModal').html('<textarea name="alterar_interacao" id="alterar_interacao"()"></textarea>');
                tinymce.init({
                    selector: '#alterar_interacao',
                    statusbar: false,
                    menubar: false,
                    resize: true,
                    toolbar1: 'undo redo | fontselect fontsizeselect | bold italic underline | strikethrough inline | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent',
                    auto_focus: 'alterar_interacao',
                    entity_encoding: 'raw',
                    height: '250'
                });
                $timeout(function() {
                    tinyMCE.activeEditor.setContent(descricao);
                    $timeout(function() {
                        tinyMCE.get('alterar_interacao').focus();
                    }, 500);
                }, 500);*/

                $('#alterar_interacao').trumbowyg('destroy');
                $('#textareaModal').html('<textarea name="alterar_interacao" id="alterar_interacao"></textarea>');
                $('#alterar_interacao').trumbowyg({
                    svgPath: '/app/plugins/trumbowyg/dist/ui/icons.svg',
                    btns: [
                        ['undo', 'redo'],
                        ['formatting'],
                        ['strong', 'em', 'underline'],
                        ['foreColor', 'backColor'],
                        ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
                        ['unorderedList', 'orderedList'],
                        ['horizontalRule'],
                        ['removeformat'],
                    ],
                    lang: 'pt_br',
                });
                $('#alterar_interacao').trumbowyg('html', descricao);
                $timeout(function() {
                    $('.trumbowyg-editor').focus();
                }, 500);
            });
        };


        vm.cancelarAtleracaoInteracao = function() {
            //tinyMCE.activeEditor.setContent('');
            $('#alterar_interacao').trumbowyg('empty');
            $('#modalInteracao').modal('hide');
        };


        vm.updateInteracao = function() {
            vm.salvandoAtd = true;
            angular.element('.salvando_atendimento').html('<div class="spinner"></div>');
            //vm.atdDescricao = tinyMCE.get('alterar_interacao').getContent();
            vm.atdDescricao = $('#alterar_interacao').trumbowyg('html');
            var dados = {
                'id': vm.atdID,
                'user_hash': Cookies.get('crm_funil_hash'),
                'descricao': vm.atdDescricao
            };
            negociosService.updateInteracao(dados).then(function(response) {
                $timeout(function() {
                    //tinyMCE.activeEditor.setContent('');
                    $('#alterar_interacao').trumbowyg('empty');
                    $timeout(function() {
                        //$('.ativar_textarea2').fadeOut(100);
                        $('.ativar_textarea2').hide();
                    });
                    swal({
                        title: 'Alterado!',
                        text: 'Alterado com sucesso!',
                        type: 'success',
                        showConfirmButton: false,
                        timer: 1000
                    });
                    $timeout(function() {
                        vm.salvandoAtd = false;
                        angular.element('.salvando_atendimento').html('Salvar');
                        vm.listarInteracoes(vm.negocios.id);
                        vm.cancelarAtleracaoInteracao();
                    }, 1000);
                });
            }, function(error) {
                $log.error(error);
            });
        };

        vm.excluirInteracao = function() {
            swal({
                    title: 'Deseja excluir este registro?',
                    text: 'Esta ação é irreversível',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#DD6B55',
                    confirmButtonText: 'Sim',
                    cancelButtonText: 'Não',
                    closeOnConfirm: false
                },
                function() {
                    $window.onkeydown = null;
                    $window.onfocus = null;
                    var dados = {
                        'id': vm.atdID
                    };
                    negociosService.excluirInteracao(dados).then(function(response) {
                        swal({
                            title: 'Removido!',
                            text: 'Interação removida com sucesso!',
                            type: 'success',
                            showConfirmButton: false,
                            timer: 1000
                        });
                        $timeout(function() {
                            //tinyMCE.activeEditor.setContent('');
                            $('#nova_interacao').trumbowyg('empty');
                            $('#alterar_interacao').trumbowyg('empty');
                            vm.salvandoAtd = false;
                            vm.listarInteracoes(vm.negocios.id);
                            vm.cancelarAtleracaoInteracao();
                        }, 1000);
                    }, function(error) {
                        $log.error(error);
                    });
                },
                function(error) {
                    $log.error(error);
                });
        };

        vm.maisInteracoes = function() {
            $timeout(function() {
                vm.limiteInteracao = vm.limiteInteracao + 5;
            }, 400);
        };

        vm.validaAtrasado = function(data) {
            var dataatual = new Date();
            var d = dataatual.getDate();
            var m = dataatual.getMonth();
            m += 1;
            var y = dataatual.getFullYear();
            if (d < 10) {
                d = '0' + d;
            }
            if (m < 10) {
                m = '0' + m;
            }
            var data_formatada = (d + '/' + m + '/' + y);
            var atual = moment(data_formatada, 'DD/MM/YYYY');
            var escolhida = moment(data, 'DD/MM/YYYY');
            if (escolhida.diff(atual) < 0) {
                return true;
            } else {
                return false;
            }
        };

        vm.ativo = 1;
        vm.tamanhoMenuMobile = function() {
            $('.box_menu_interno_mobile').css('width', screen.width - 30 + 'px');
        };

        vm.mobile = false;
        $(function() {
            if (screen.width < 767) {
                vm.mobile = true;
            } else {
                vm.mobile = false;
            }
        });

        if (vm.mobile) {
            $('.box_menu_interno_mobile').css('width', screen.width - 30 + 'px');
        }

        vm.ativo = 1;

        /*ADICIONAR PRODUTOS --- INÍCIO*/
        vm.produto = [];
        vm.produtosAdd = [];
        vm.valorProposta = 0;
        vm.contrato = false;
        vm.pagOnline = false;
        vm.attDados = function () {
            $timeout(function() {
                console.log('vm.attDados')
                vm.negocios.valor = vm.valorProposta;
                vm.getTotalLiquidNeg = vm.valorProposta;
            })
        }
        // vm.produtosAdd = [
        //     {
        //         "prod_id": 235,
        //         "prod_nome": "Ingresso Premium",
        //         "prod_sigla": "Ingresso Premium",
        //         "prod_slug": "Ingresso Premium",
        //         "prod_visivel": 1,
        //         "prod_qtd": 2,
        //         "prod_qtd_original": 2,
        //         "prod_valor": 150,
        //         "prod_valor_total": 300,
        //         "prod_valor_total_original": 300,
        //         "price": 150,
        //         "quantity": 30,
        //         "event_id": 90921,
        //         "group_discounts": [
        //             {
        //                 "id": 9,
        //                 "product_id": 235,
        //                 "min_quantity": 15,
        //                 "promo_value": 95,
        //                 "max_discount": 10
        //             }
        //         ],
        //         "bonifications": [
        //             {
        //                 "id": 5,
        //                 "product_id": 235,
        //                 "min_quantity": 10,
        //                 "max_bonus_quantity": 15,
        //                 "ticket_bonus_id": 235
        //             }
        //         ],
        //         "$$hashKey": "object:516"
        //     },
        //     {
        //         "id": 234,
        //         "title": "Palestra Fulano",
        //         "price": 500,
        //         "quantity": 47,
        //         "event_id": 90921,
        //         "group_discounts": [
        //             {
        //                 "id": 2,
        //                 "product_id": 234,
        //                 "min_quantity": 10,
        //                 "promo_value": null,
        //                 "max_discount": 5
        //             },
        //             {
        //                 "id": 7,
        //                 "product_id": 234,
        //                 "min_quantity": 15,
        //                 "promo_value": null,
        //                 "max_discount": 10
        //             },
        //             {
        //                 "id": 3,
        //                 "product_id": 234,
        //                 "min_quantity": 20,
        //                 "promo_value": 10,
        //                 "max_discount": 15
        //             },
        //             {
        //                 "id": 4,
        //                 "product_id": 234,
        //                 "min_quantity": 25,
        //                 "promo_value": null,
        //                 "max_discount": 20
        //             },
        //             {
        //                 "id": 8,
        //                 "product_id": 234,
        //                 "min_quantity": 30,
        //                 "promo_value": 100,
        //                 "max_discount": 25
        //             }
        //         ],
        //         "bonifications": [
        //             {
        //                 "id": 3,
        //                 "product_id": 234,
        //                 "min_quantity": 10,
        //                 "max_bonus_quantity": 1,
        //                 "ticket_bonus_id": 233
        //             },
        //             {
        //                 "id": 1,
        //                 "product_id": 234,
        //                 "min_quantity": 20,
        //                 "max_bonus_quantity": 2,
        //                 "ticket_bonus_id": 233
        //             },
        //             {
        //                 "id": 4,
        //                 "product_id": 234,
        //                 "min_quantity": 30,
        //                 "max_bonus_quantity": 3,
        //                 "ticket_bonus_id": 234
        //             },
        //             {
        //                 "id": 2,
        //                 "product_id": 234,
        //                 "min_quantity": 40,
        //                 "max_bonus_quantity": 5,
        //                 "ticket_bonus_id": 233
        //             }
        //         ],
        //         "prod_id": 234,
        //         "prod_nome": "Palestra Fulano",
        //         "prod_sigla": "Palestra Fulano",
        //         "prod_slug": "Palestra Fulano",
        //         "prod_visivel": 1,
        //         "prod_qtd": 3,
        //         "prod_qtd_original": 3,
        //         "prod_valor": 500,
        //         "prod_valor_total": 1500,
        //         "prod_valor_total_original": 1500,
        //         "$$hashKey": "object:1542"
        //     }
        // ];
        /*ADICIONAR PRODUTOS --- FIM*/


        vm.mostrarBtnEditar = false; // Mostrar ou não botão de eidtar ou de salvar dados editados
        vm.mostrarBtnExcluir = false; // Mostrar ou não botão excluir
        vm.verifMostrarBtnEditar = function() {
            var local = window.localStorage.getItem('5c479de2');
            var valLocal = JSON.parse(API.funilRC.decrypt(local));
            var localPessoas = window.localStorage.getItem('isModNegociacao');
            var valLocalPessoas = JSON.parse(API.funilRC.decrypt(localPessoas));
            switch (valLocalPessoas.altera) {
                case '4':
                    $timeout(function() {
                        vm.mostrarBtnEditar = false;
                    });
                    break;
                case '3':
                    if (valLocal[0].user_id == vm.negocios.responsavel) {
                        $timeout(function() {
                            vm.mostrarBtnEditar = true;
                        });
                    } else {
                        $timeout(function() {
                            vm.mostrarBtnEditar = false;
                        });
                    };
                    break;
                case '2':
                    if (valLocal[0].fk_gru_id == vm.negocios.fk_gru_id) {
                        $timeout(function() {
                            vm.mostrarBtnEditar = true;
                        });
                    } else {
                        $timeout(function() {
                            vm.mostrarBtnEditar = false;
                        });
                    };
                    break;
                default:
                    $timeout(function() {
                        vm.mostrarBtnEditar = true;
                    });
            }
            switch (valLocalPessoas.exclui) {
                case '4':
                    $timeout(function() {
                        vm.mostrarBtnExcluir = false;
                    });
                    break;
                case '3':
                    if (valLocal[0].user_id == vm.negocios.responsavel) {
                        $timeout(function() {
                            vm.mostrarBtnExcluir = true;
                        });
                    } else {
                        $timeout(function() {
                            vm.mostrarBtnExcluir = false;
                        });
                    };
                    break;
                case '2':
                    if (valLocal[0].fk_gru_id == vm.negocios.fk_gru_id) {
                        $timeout(function() {
                            vm.mostrarBtnExcluir = true;
                        });
                    } else {
                        $timeout(function() {
                            vm.mostrarBtnExcluir = false;
                        });
                    };
                    break;
                default:
                    $timeout(function() {
                        vm.mostrarBtnExcluir = true;
                    });
            }
        }
        //vm.naoMostrarBtnSalvarResp();

        /*DATA GANHO/PERDA -- INÍCIO*/
        vm.alterarDataGanhoPerda = function(id_neg) {
            vm.altDataGanhoPerda = [];
            vm.id_neg = id_neg;
            vm.data_ganho_perda = angular.copy(vm.negocios.data_ganho_perda);
            $('#modalAltGanhoPerda').modal({
                show: true
            }).on('shown.bs.modal', function(e) {
                $('#campo_data_ganho_perda').focus();
                $('#campo_data_ganho_perda').daterangepicker({
                    singleDatePicker: true,
                    showDropdowns: true,
                    maxDate: moment().format('DD/MM/YYYY'),
                    locale: {
                        format: 'DD/MM/YYYY'
                    }
                });
                $('#campo_data_ganho_perda').data('daterangepicker').setStartDate(vm.negocios.data_ganho_perda);
                $("#campo_data_ganho_perda").trigger("click");
            });
        }

        vm.salvarDataGanhoPerda = function(id_neg) {
            var dados = {
                'data': vm.data_ganho_perda,
                'id': id_neg,
                'user_hash': Cookies.get('crm_funil_hash')
            };
            if (vm.editando) {
                vm.negocios.data_ganho_perda = vm.data_ganho_perda;
                $('#modalAltGanhoPerda').modal('hide');
            } else {
                negociosService.alterarDataGanhoPerda(dados).then(function(response) {
                    vm.negocios.data_ganho_perda = vm.data_ganho_perda;
                    $('#modalAltGanhoPerda').modal('hide');
                    vm.carregarHistorico(id_neg);
                    swal({
                        title: 'Alterado com sucesso!',
                        type: 'success',
                        text: 'Data alterada com sucesso!',
                        showConfirmButton: false,
                        timer: 1000
                    });
                }, function(error) {
                    $log.error(error);
                });
            }
        }
        /*DATA GANHO/PERDA -- FIM*/


        /*MODAL NOVO COMPROMISSO RÁPIDO --- INÍCIO*/

        vm.modalNovoCompromisso = function() {
            $('#modalNovoCompromisso').modal("show");

            var dados = {
                "id": vm.negocios.id,
                "cliente_empresa": vm.negocios.cliente_empresa,
                "responsavel": vm.negocios.responsavel
            }
            $rootScope.$broadcast('modal_neg', dados);

            //angular.element("#id_neg_modalComp").val(vm.negocios.id);

            $timeout(function() {
                $(".campo_nome_comp").focus();
            }, 500);
        }

        $('#modalNovoCompromisso').on('hidden.bs.modal', function() {
            vm.buscarCompromissosNeg();
            vm.carregarHistorico(vm.negocios.id);
        });


        function listLink() {
            tokenNodejs.get('negociacao/lista-link/' + $routeParams.slugNegocio)
                .then((result) => {
                    console.log(result)
                    let listsData = result.data.data.response;
                    vm.listLink = listsData;
                    if (listsData.length) {
                        visualizacaoNegociacao($routeParams.slugNegocio)
                    } else {
                        $timeout(() => vm.listHistoricoLink = []);
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
        vm.getListLink = () => listLink();

        function visualizacaoNegociacao(urlLink) {
            tokenNodejs.get(`negociacao/list-history/${urlLink}`)
                .then((result) => {
                    try {
                        $timeout(() => vm.listHistoricoLink = result.data.data.response);
                    } catch (e) {
                        $timeout(() => vm.listHistoricoLink = []);
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }

        vm.buscarCompromissosNeg = function() {
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) {
                vm.saasid = items;
                var dados = {
                    'neg_id': vm.negocios.id,
                    'user_hash': Cookies.get('crm_funil_hash'),
                    'mod_id': 4,
                    'saasid': vm.saasid
                }
                negociosService.buscarCompromissosNeg(dados).then(function(response) {
                    /*console.log("buscarCompromissosNeg");
                    console.log(response.data);*/
                    if (response.data != 0) {
                        vm.compromissosNegLista = response.data;
                    } else {
                        vm.compromissosNegLista = '';
                    }
                }, function(error) {
                    $log.error(error);
                });
            });
        }
        /*MODAL NOVO COMPROMISSO RÁPIDO --- FIM*/

        vm.verifRoleEditar = function() {
            var local = window.localStorage.getItem('5c479de2');
            var valLocal = JSON.parse(API.funilRC.decrypt(local));
            var localPessoas = window.localStorage.getItem('isModCompromissos');
            var valLocalPessoas = JSON.parse(API.funilRC.decrypt(localPessoas));
            switch (parseInt(valLocalPessoas.altera)) {
                case 4:
                    $timeout(function() {
                        vm.roleSemAcesso = true;
                    });
                    break;
                case 3:
                    $timeout(function() {
                        vm.userIdRole = valLocal[0].user_id;
                    });
                    break;
                case 2:
                    $timeout(function() {
                        vm.grupoIdRole = valLocal[0].fk_gru_id;
                    });
                    break;
                default:
                    $timeout(function() {
                        vm.mostrarBtnEditar = true;
                    });
            }
        }

        vm.finalizarComp = function(slug, index, id, tipo, finalizado) {
            $timeout(function() {
                //$('.icon_check_lista_' + id).hide();
                $('.final_' + id).hide();
                $('.spinner_check_lista_' + id).removeClass('hide'); // mostra o spinner loading
                var dados = {
                    'tipo': tipo,
                    'slug': slug,
                    'finalizado': ''
                };
                if (finalizado == 0) { // marcado
                    dados.finalizado = 1;
                } else { // não marcado
                    dados.finalizado = 0;
                }

                compromissosService.marcarFinalizadoRapido(dados).then(function(response) {
                    $('.spinner_check_lista_' + id).addClass('hide'); // esconde o spinner loading
                    $('.icon_check_lista_' + id).fadeIn('slow'); // mostra o check sucesso
                    $timeout(function() {
                        vm.buscarCompromissosNeg();
                    }, 350);
                    $timeout(function() { // após 2 segundos remove o check sucesso
                        $('.icon_check_lista_' + id).fadeOut('slow');
                    }, 2000);
                }, function(error) {
                    $log.error(error);
                });
            });
        }

        vm.limiteCompNeg = 5;
        vm.acabouCompNeg = function() {
            vm.verifRoleEditar();
        }

        vm.maisCompNeg = function() {
            $timeout(function() {
                vm.limiteCompNeg = vm.limiteCompNeg + 5;
            });
            $timeout(function() {
                $('[data-toggle="tooltip"]').tooltip();
            }, 500);
        };

        vm.tooltipComp = function(finalizado) {
            return finalizado == 1 ? 'Clique para reabrir' : 'Clique para finalizar'
        }

        vm.criarDados = function() {
            //criando cookies de validação para um cadastro
            $timeout(function() {
                Cookies.set('pessoa_slug', '' + vm.slugClienteN + '');
                Cookies.remove('empresa_slug');
                Cookies.remove('empresa_slug_update');
                Cookies.remove('pessoa_slug_update');
            });
        };

        var tentativa = 0;
        vm.verificarCookieOrcCompufacil = function() {
            /* ESTÁ FUNÇÃO É UTILIZADA PARA FAZER 2 TENTATIVAS DE ATUALIZAÇÃO DE HISTÓRICO,
            COM O OBJETIVO DE CARREGAR O HISTÓRICO DE ORÇAMENTO CRIADO NO COMPUFACIL,
            POIS COMO SÃO UTILIZADAS FUNÇÕES SEPARADAS PARA A CRIAÇÃO E TAMBÉM A ROTA É ALTERADA,
            ESTÁ VERIFICAÇÃO É NECESSÁRIA*/
            tentativa++;
            if (Cookies.get('funilpro_orc_compufacil')) {
                if (Cookies.get('funilpro_orc_compufacil') == vm.negocios.id) {
                    vm.carregarHistorico(vm.negocios.id);
                    $timeout(function() {
                        if (tentativa < 3) {
                            vm.verificarCookieOrcCompufacil();
                        } else {
                            Cookies.remove('funilpro_orc_compufacil');
                        }
                    }, 5000);
                }
            }
        }

        /*PEDIDO DE VENDA COMPUFÁCIL --- INÍCIO*/

        vm.detalhesPedidoVenda = function(id) {
            $('#modalPedidoVenda').modal("show");
            vm.carregarPedidoVenda(id);
        }

        vm.carregandoPedidoVenda = false;
        vm.carregarPedidoVenda = function(id) {
            vm.carregandoPedidoVenda = true;
            var dados = {
                'id': id,
                'saasid': vm.saasid
            };
            negociosService.carregarPedidoVenda(dados).then(function(response) {
                // console.log("carregarPedidoVenda");
                // console.log(response.data);
                /*return null;*/
                if (response.data != 0) {
                    $timeout(function() {
                        vm.pedidoVenda = response.data.pedido;
                        vm.formasPag = response.data.formas_pag;
                        vm.carregandoPedidoVenda = false;
                    });
                } else {
                    $timeout(function() {
                        vm.pedidoVenda = '';
                        vm.formasPag = '';
                        vm.carregandoPedidoVenda = false;
                    });
                }
            }, function(error) {
                $log.error(error);
            });
        }

        vm.acabouRange = function() {
            $timeout(function() {
                vm.carregandoPedidoVenda = false;
                angular.forEach(vm.pedidoVenda.paymentMode.range, function(value, key) {
                    $('.data_venc_' + key).daterangepicker({
                        singleDatePicker: true,
                        showDropdowns: true,
                        drops: "up",
                        startDate: value.date,
                        locale: {
                            format: 'DD/MM/YYYY'
                        }
                    });
                });
            }, 500);
        }

        vm.atualizarParcelas = function(id) {
            var metodo = $filter('filter')(vm.formasPag, { 'id': id });
            var repeat = metodo[0].repeatTimes;
            var tipoNome = metodo[0].repeatType.modifier.periodName;
            var tipoQtd = metodo[0].repeatType.modifier.periodQty;
            var entrada = metodo[0].requiresEntryPayment;
            var total = vm.pedidoVenda.price;
            var unit = parseFloat(parseFloat(vm.pedidoVenda.price / repeat).toFixed(2));
            var sobra = parseFloat(vm.pedidoVenda.price % repeat) / 100;

            var array = [];
            if (!entrada) {
                var data = moment().add(tipoQtd, tipoNome).format('DD/MM/YYYY');
                var qtd = 1;
            } else {
                var data = moment().format('DD/MM/YYYY');
                var qtd = 0;
            }

            for (var a = 0; a < repeat; a++) {
                if (a == 0) {
                    array.push({ 'value': parseFloat(unit + sobra), 'date': data });
                } else {
                    array.push({ 'value': parseFloat(unit), 'date': data });
                }
                qtd = parseInt(qtd) + parseInt(tipoQtd);
                data = moment().add(qtd, tipoNome).format('DD/MM/YYYY');
            }
            vm.pedidoVenda.paymentMode = metodo[0];
            vm.pedidoVenda.paymentMode.range = array;
            console.log("vm.pedidoVenda");
            console.log(vm.pedidoVenda);
        }

        vm.salvarPedidoVenda = function() {
            vm.salvandoPedidoVenda = true;
            var dados = {
                'pedido_venda': vm.pedidoVenda,
                'saasid': vm.saasid
            }
            negociosService.salvarPedidoVenda(dados).then(function(response) {
                // console.log("salvarPedidoVenda");
                // console.log(response.data);
                vm.salvandoPedidoVenda = false;
                return null;
                swal({
                    title: 'Vínculado com sucesso!',
                    type: 'success',
                    text: 'Vínculo realizado com sucesso!',
                    showConfirmButton: false,
                    timer: 2000
                });
                vm.salvandoPedidoVenda = false;
                $('#modalPedidoVenda').modal("hide");
            }, function(error) {
                $log.error(error);
            });
        }

        /*PEDIDO DE VENDA COMPUFÁCIL --- FIM*/

        /*ENVIAR E-MAIL --- INICIO*/

        vm.includeModalEmail = '';
        vm.modalEnviarEmail = function(id) {
            vm.includeModalEmail = 'negocios/template/modal_enviar_email.html';
            $('#modalEnviarEmail').modal("show");
            $timeout(function() {
                var dados = {
                    "id": vm.negocios.id,
                    "cliente_empresa": vm.negocios.cliente_empresa,
                    "responsavel": vm.negocios.responsavel
                }
                $rootScope.$broadcast('modal_enviar_email', dados);
            }, 200);
        }

        $rootScope.$on('atualizar_historico', function(event, data) {
            // glauber
            vm.carregarHistorico(data.id, data.idEmail);
        });

        /*ENVIAR E-MAIL --- FIM*/


        vm.descontNegChangePor = function(g) {
            if (g) {
                $("#valor_neg_desc_vl").hide()
                $("#valor_neg_desc_por").show()
            } else {
                $("#valor_neg_desc_vl").show()
                $("#valor_neg_desc_por").hide()
            }
        }


        vm.adicionarDescontoNegociacao = function(vl) {

            if (vl.porc) {
                vm.descontoNegociacao.push({
                    "fk_prop_id": vm.negocios.id,
                    "propdesc_desconto": vl.porcentagem,
                    "propdesc_valor": "",
                    "propdesc_is_porc": true,
                    "sinal": "%"
                })

            } else {
                vm.descontoNegociacao.push({
                    "fk_prop_id": vm.negocios.id,
                    "propdesc_desconto": vl.valor,
                    "propdesc_valor": "",
                    "propdesc_is_porc": false,
                    "sinal": "$"
                })

            }

            vm.descontNeg = {
                "porc": false,
                "valor": "",
                "porcentagem": ""
            }

            vm.SetTotalLiquidoNeg(null, 'add')
            vm.descontNegChangePor(vm.descontNeg.porc)

        }


        vm.removerNegDescPosition = function(del, a, vl) {
            vm.descontoNegociacaoUpdateBanco.push(a)
            vm.descontoNegociacao.splice(del, 1)
            setTimeout(function() {
                vm.SetTotalLiquidoNeg(null, 'remover')
            }, 10);
        }


        vm.SetTotalLiquidoNeg = function(d, onde) {

            if (onde == 'get') {
                if (d) {
                    vm.getTotalLiquidNeg = d
                }
                var ValueOrigSemDesc = vm.getTotalLiquidNeg;

                angular.forEach(vm.descontoNegociacao, function(value, key) {
                    $timeout(function() {
                        if (value.sinal == '%') {
                            vm.getTotalLiquidNeg = vm.getTotalLiquidNeg - (vm.getTotalLiquidNeg * value.propdesc_desconto);
                            vm.descontoNegociacao[key].propdesc_valor = ValueOrigSemDesc - vm.getTotalLiquidNeg;
                        } else {
                            vm.getTotalLiquidNeg = vm.getTotalLiquidNeg - value.propdesc_desconto;
                            vm.descontoNegociacao[key].propdesc_valor = ValueOrigSemDesc - vm.getTotalLiquidNeg;
                        }

                    });
                });

            } else if (onde == 'add') {
                var ValueOrigSemDesc = vm.getTotalLiquidNeg;
                var positionGet = vm.descontoNegociacao.length - 1;


                if (vm.descontoNegociacao[positionGet].sinal == '%') {
                    vm.getTotalLiquidNeg = vm.getTotalLiquidNeg - (vm.getTotalLiquidNeg * vm.descontoNegociacao[positionGet].propdesc_desconto);
                    vm.descontoNegociacao[positionGet].propdesc_valor = ValueOrigSemDesc - vm.getTotalLiquidNeg;
                } else {
                    vm.getTotalLiquidNeg = vm.getTotalLiquidNeg - vm.descontoNegociacao[positionGet].propdesc_desconto;
                    vm.descontoNegociacao[positionGet].propdesc_valor = ValueOrigSemDesc - vm.getTotalLiquidNeg;
                }


            } else if (onde == 'remover') {
                if (vm.descontoNegociacao.length) {

                    vm.getTotalLiquidNeg = parseFloat(vm.negocios.valor);

                    for (var i = 0; i < vm.descontoNegociacao.length; i++) {
                        if (vm.descontoNegociacao[i].sinal == '%') {
                            vm.getTotalLiquidNeg -= parseFloat(parseFloat(vm.getTotalLiquidNeg) * parseFloat(vm.descontoNegociacao[i].propdesc_desconto));
                        } else {
                            vm.getTotalLiquidNeg -= parseFloat(vm.descontoNegociacao[i].propdesc_desconto);
                        }
                    }
                    $scope.$apply()
                } else {
                    vm.getTotalLiquidNeg = vm.negocios.valor;
                    $scope.$apply()
                }
            }


            // analisa mostrar msg "A negociação foi zerada. Cálculo de desconto resultou negativo"
            if (vm.getTotalLiquidNeg < 0) {
                $('#msgDescMaiorTotal').css("display", "block");

                $timeout(function() {
                    $('#msgDescMaiorTotal').css("display", "none");
                }, 5000);
            } else {
                $('#msgDescMaiorTotal').css("display", "none");
            }



        }



        vm.carregandoLogEmail = false;
        vm.verEmailLog = function(idEmail) {
            // console.log('email', idEmail)
            var id_Email = idEmail;
            $("#modalEmailLog").modal("show");
            vm.carregandoLogEmail = true;

            FactoryLogEmail.getEmail(idEmail).then(function(resposta) {
                var htmlLog = resposta.data.data.response.email_conteudo_html;

                $(".box_inserir_log_email").html(htmlLog);

                vm.emailLog = resposta.data.data.response;

                $timeout(function() {
                    vm.carregandoLogEmail = false;
                }, 10);

                // console.log('vm.emailLog => ', vm.emailLog)
            })

        }


        /*MOTIVO PERDA DA NEGOCIAÇÃO --- INICIO*/
        vm.modalMotivoNeg = function(Prop, $event) {
            Prop.prop_id = Prop.id;
            Prop.prop_nome = Prop.nome;
            if (Prop.cliente_empresa.tipo == "Pessoas") {
                Prop.cli_nome = Prop.cliente_empresa.nome;
            } else {
                Prop.emp_nome = Prop.cliente_empresa.nome;
            }
            $timeout(function() {
                $("#enviandomotivoPerdaModal").on('click', function() {
                    $timeout(function() {
                        $('.spinner_' + Prop.id).toggleClass('hide');
                        $('.ganhou_' + Prop.id).toggleClass('hide');
                        $('.perdeu_' + Prop.id).toggleClass('hide');
                        vm.negocios.ganhou = 0;
                        vm.negocios.perdeu = 1;
                        vm.negocios.data_ganho_perda = moment().format('DD/MM/YYYY');
                        vm.carregarHistorico(Prop.id);

                    }, 1000);
                });
            }, 1000);

            FactoryModalMotivo.openModal(Prop, $event);
        }
        /*MOTIVO PERDA DA NEGOCIAÇÃO --- FIM*/

        vm.tabSelecionada = 0;

        /*MÚLTIPLOS FUNIS DE VENDA --- INÍCIO*/
        vm.listarFunisVendas = function() {
            vm.funisVenda = [];
            loadFunisVenda().then(function(response) {
                $timeout(function() {
                    vm.funisVenda = response;
                });
            });
        };
        vm.listarFunisVendas();
        /*MÚLTIPLOS FUNIS DE VENDA --- FIM*/

        // TURMAS VENDAS COMPLEXAS - A9 --- INÍCIO
        vm.nomeTurmaVC = '';
        vm.buscaTurmasVC = function () {
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function (items) { // ler array de retorno
                vm.saasid = items;
                var dados = {
                    'saasid': vm.saasid,
                };
                turmasVCService.findAll(dados).then(function (response) {
                    if (response.data != 0) {
                        $timeout(function () {
                            vm.turmasVCRepeat = response.data;
                            angular.forEach(vm.turmasVCRepeat, function(value, key) {
                                if (vm.negocios.fk_tvc_id == value.tvc_id){
                                    vm.nomeTurmaVC = value.tvc_nome;
                                }
                            });
                        });
                    } else {
                        $timeout(function () {
                            vm.turmasVCRepeat = '';
                        });
                    }
                }, function (error) {
                    $log.error(error);
                });
            });
        };
        // TURMAS VENDAS COMPLEXAS - A9 --- FIM
    }
})();